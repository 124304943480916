import React from 'react'
import './Button.css'

import {MdEdit} from '@react-icons/all-files/md/MdEdit.esm'
import {BsEyeFill} from '@react-icons/all-files/bs/BsEyeFill.esm'
import {FaTrashAlt} from '@react-icons/all-files/fa/FaTrashAlt.esm'
import {BsFillCaretRightFill} from '@react-icons/all-files/bs/BsFillCaretRightFill.esm'
import {BsFillCaretLeftFill} from '@react-icons/all-files/bs/BsFillCaretLeftFill.esm'

function Button(props) {

  const btnStyle = {
    padding : props.title == "view" || 
              props.title == "edit" || 
              props.title == "delete" || 
              props.title == "next" || 
              props.title == "add" || 
              props.title == "prev" ? "0.5em 1em" : ""
  }

  return (
    <button 
        style={props.style} 
        className={`${props.variant} ${btnStyle} button `} 
        name={props.name} 
        onClick={props.onClick} 
        disabled={props?.buttonLoading}
        >{
          props.title == "view" ? <BsEyeFill /> : 
          props.title == "edit" ? <MdEdit /> :
          props.title == "delete" ? <FaTrashAlt /> :
          props.title == "next" ? <BsFillCaretRightFill /> :
          props.title == "add" ? <h3>+</h3> :
          props.title == "prev" ? <BsFillCaretLeftFill /> 
          : props?.buttonLoading ? "Loading..." : props.title
          }</button>
  )
}

export default Button