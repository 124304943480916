import { createSlice } from "@reduxjs/toolkit";
import customerService from "../services/axios/customer";

export const getCustomer = (param) => async (dispatch) => {
  // dispatch(loadingStatus(true));

  return customerService
    .list(param)
    .then(async (res) => {

      return res?.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getDetails = (param) => async (dispatch) => {
  // dispatch(loadingStatus(true));

  return await customerService
    .details(param)
    .then(async (res) => {
      return res?.data;
    })
    .catch((error) => {
      return error;
    });
};

const initialState = {
  list: [],
  totalCustomer:0
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCounter:(state,action)=>{
        state.counter=action.payload
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {},
});

export const { setLoading } = customerSlice.actions;

export default customerSlice.reducer;
