import './adminpanel.css';
import React, { useEffect } from 'react'
import PanelLayout from './adminPanel/panelComponents/PanelLayout/PanelLayout';
import Dashboard from './Pages/Dashboard/Dashboard';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';



function AdminPanel() {
  const isLoggedIn = useSelector((store) => store.auth?.isLoggedIn);
  // const isLoggedIn = true;
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login')
    }
  }, [isLoggedIn])

  // console.log('is logged in',isLoggedIn)

  
    return <div>
      {isLoggedIn ?
        <PanelLayout >
          <Dashboard />
        </PanelLayout>
      :''}
    
    </div>

}

export default AdminPanel