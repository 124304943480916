import React from 'react'
import './Input.css'

function Input(props) {
  return (<>
  
    <div className="inputSection flex-column ">
        {props?.label && <label className="inputLabel">{props.label}</label>}
        <input
            className="input"
            type={props?.type}
            disabled={props?.disabled}
            name={props?.name}
            value={props?.value}
            placeholder={props?.hint}
            onChange={(e) =>props?.handleChange(props?.name, e?.target?.value)}
            autocomplete='off'
        />
        {props?.error && <span className="inputError" >{props?.error}</span>}
    </div>

    </>
  )
}

 Input.defaultProps = {
    type:"text",
    handleChange:()=>null
}

export default Input