import './PanelLayout.css';
import React, { useState } from 'react';
import PanelNavbar from '../PanelNavbar/PanelNavbar';
import PanelSlideMenu from '../PanelSlideMenu/PanelSlideMenu';
import { Outlet, useNavigate } from 'react-router-dom';

import {BsShop} from 'react-icons/bs'
import {HiHomeModern} from 'react-icons/hi2'
import {FiTruck} from '@react-icons/all-files/fi/FiTruck.esm'
import {HiUserGroup} from 'react-icons/hi'
import {AiOutlineLogout} from '@react-icons/all-files/ai/AiOutlineLogout.esm'
import { useDispatch } from 'react-redux';
import { getLogout } from '../../../store/authSlice';
import { IoIosSettings } from "react-icons/io";
import { TbPhotoSquareRounded } from "react-icons/tb";
import { confirm } from '../../../BTUI/BtAlert';

function PanelLayout(props) {

    const [isToggle, setIsToggle] = useState(false);
    const [isHover, setIsHover] = useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleClick =()=>{
        setIsToggle((prev) => !prev)
    }

    const handleHover =(index)=>{
        setIsHover(index == index ? true: false)
    }

    const handleNotHover =(index)=>{
        setIsHover(false)
    }


    const handleLogout = (e) => {
        e.preventDefault()

        confirm(
            'Are You Sure You want to logout ?',
            ()=>dispatch(getLogout('/login'))
                .then((resp) => {
                    navigate('/login')
                })
                .catch((err) => {
                    // window.alert(err.message)
                    // console.log(err)
                }),
            ()=>{},
            {
                acceptTitle:'Log out',
                acceptBackground: "#ef3f49"
            }
        )
        
    }

    return (
        <div className='panelLayoutSection flex'>
            <div className='panelLayoutSlideMenu flex'
                style={{
                    maxWidth: !isToggle ? '5em' :'15em',
                    width: '100%',
                    position: 'fixed',
                    background: 'white',
                }}
            >
                <PanelSlideMenu 
                    menus={[
                        {   
                            href: '/', 
                            name: 'Dashboard', 
                            icon: <HiHomeModern />,
                            isHover: isHover,
                            onMouseEnter:handleHover,
                            onMouseLeave:handleNotHover
                        },
                        {
                            href: '/products', 
                            name: 'Product', 
                            icon: <BsShop />,
                            isHover: isHover,
                            onMouseEnter:handleHover,
                            onMouseLeave:handleNotHover
                        },
                        {
                            href: '/customer', 
                            name: 'Customer', 
                            icon: <HiUserGroup />,
                            isHover: isHover,
                            onMouseEnter:handleHover,
                            onMouseLeave:handleNotHover
                        },
                        
                        {
                            href: '/orders', 
                            name: 'Orders', 
                            icon: <FiTruck />,
                            isHover: isHover,
                            onMouseEnter:handleHover,
                            onMouseLeave:handleNotHover
                        },
                        {
                            href: '/banner', 
                            name: 'Banner', 
                            icon: <TbPhotoSquareRounded />,
                            isHover: isHover,
                            onMouseEnter:handleHover,
                            onMouseLeave:handleNotHover
                        },
                        // {
                        //     href: '/settings', 
                        //     name: 'Settings', 
                        //     icon: <IoIosSettings />,
                        //     isHover: isHover,
                        //     onMouseEnter:handleHover,
                        //     onMouseLeave:handleNotHover
                        // },
                        {
                            href: '/logout', 
                            name: 'Logout', 
                            icon: <AiOutlineLogout />,
                            isHover: isHover,
                            onClick: handleLogout,
                            onMouseEnter:handleHover,
                            onMouseLeave:handleNotHover
                        },
                    ]}

                    isToggle={isToggle}
                    
                    
                />
                
            </div>
            <div className=''
                    style={{
                        width: !isToggle ? '5em': '17.6em'
                    }}
                ></div>
            <div className='panelLayoutNavbar w-100'>
                <PanelNavbar 
                    isToggle={isToggle}
                    onClick={handleClick}
                />
                <div className=''
                style={{
                    height: '3.55em',
                }}
                >

                </div>
                <div className='panelLayoutContent w-'>
                    {/* {props.children} */}
                    <Outlet />
                    
                </div>
            </div>
            
            
        </div>
    );
}


export default PanelLayout