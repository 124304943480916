import { useEffect, useState } from 'react';
import './style.css';
import bannerService from '../../../services/axios/banner';
import { useParams } from 'react-router-dom';

const BannerDetail=()=> {
    const [bannerDetail, setBannerDetail] = useState([])
    const [imagePath, setImagePath] = useState(null)

    const {id} = useParams()
    useEffect(()=> {
        bannerService?.details({id})
            .then((res)=> {
                // console.log(res)
                setBannerDetail(res?.data?.response?.details)
                setImagePath(res?.data?.response)
            })
            .catch((err)=> {
                // console.log(err)
            })
    },[])
    
    return<>
        <div className="banner-detail-section">
            <BannerImage 
                image={imagePath?.banner_image_path+bannerDetail?.[0]?.image}
            />
            <BannerText
                heading="Banner Details"
            >
                <div className="banner-details-card">
                    <b>Id</b>
                    <p>{bannerDetail?.[0]?.id}</p>
                </div>
                <div className="banner-details-card">
                    <b>Image</b>
                    <p>{bannerDetail?.[0]?.image}</p>
                </div>
                <div className="banner-details-card">
                    <b>Serial Number</b>
                    <p>{bannerDetail?.[0]?.serial_number}</p>
                </div>
                <div className="banner-details-card">
                    <b>Type</b>
                    <p>{bannerDetail?.[0]?.type}</p>
                </div>
                <div className="banner-details-card">
                    <b>Status</b>
                    <p>{bannerDetail?.[0]?.status}</p>
                </div>
                <div className="banner-details-card">
                    <b>Added On</b>
                    <p>{bannerDetail?.[0]?.added_on}</p>
                </div>
                <div className="banner-details-card">
                    <b>Added By</b>
                    <p>{bannerDetail?.[0]?.added_by}</p>
                </div>
            </BannerText>
        </div>
    </>
}

export default BannerDetail

const BannerImage = ({image})=> {

    return<div className="banner-image-section">
        <div className="banner-image-abs-div">
            <img src={image} />
        </div>
    </div>
}

const BannerText = ({heading,children})=> {

    return<div className="banner-text-section">
        <div className=''>
            <h2 style={{marginBottom: '0.5em'}}>{heading}</h2>
            <hr/>
        </div>
        <div className="banner-text-div">
            {children}
        </div>
    </div>
}