import './PanelNavbar.css'
import React from 'react'
import { RiMenu2Line } from '@react-icons/all-files/ri/RiMenu2Line.esm'
import { RiSettings4Fill } from "react-icons/ri";
import { CgClose } from '@react-icons/all-files/cg/CgClose.esm';
import { NavLink } from 'react-router-dom'
import Hero from '../../../Assets/images/hero.jpg';
import Button from '../../panelCommonComponents/Button/Button'


function PanelNavbar(props) {

    // const [isToggle, setIsToggle] = useState(false);

    // const handleClick =()=>{
    //     setIsToggle((prev) => !prev)
    // }

    return (
        <div className='panelNavbarSection flex-between'>
            <div className='panelNavbarLogoDiv w-100'>
                <Button 
                    variant="transparent-btn" 
                    onClick={props.onClick} 
                    title={props.isToggle ? <CgClose /> : <RiMenu2Line />} 
                    style={{
                        fontSize:'1.5em'
                    }}    
                />
            </div>
            <div className='panelNavbarContent w-50 flex'>
                <ul className='flex gap-3'>
                    {/* <li><NavLink to="/update-password"><RiSettings4Fill /></NavLink></li> */}
                    <li><div className='panelNavbarProfile'
                        style={{
                            width: '2.5em',
                            height: '2.5em',
                            background: `url(${Hero})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'cover',
                            backgroundSize: '100%',
                        }}
                    ></div></li>
                </ul>
            </div>
        </div>
    )
}

export default PanelNavbar