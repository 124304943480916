import React from "react";
import "./UpdateProduct.css";
import Input from "../../../adminPanel/panelCommonComponents/Input/Input";
import TextArea from "../../../adminPanel/panelCommonComponents/TextArea/TextArea";
import Button from "../../../adminPanel/panelCommonComponents/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import productService from "../../../services/axios/product";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer,toast } from 'react-toastify';
import Checkbox from "../../../adminPanel/panelCommonComponents/Checkbox/Checkbox";
import BackBtn from "../../../adminPanel/panelCommonComponents/BackBtn/BackBtn";
// import ImageFile from "../../../adminPanel/panelCommonComponents/ImageFile/ImageFile";
import { Dropdown, FormError, SectionHeading } from "../../components/Form";
import { AiFillCamera } from "@react-icons/all-files/ai/AiFillCamera.esm";
import { BsTrashFill } from "@react-icons/all-files/bs/BsTrashFill.esm";
import { useRef } from "react";
import CheckboxMulti from "../../../adminPanel/panelCommonComponents/Checkbox/CheckboxMulti";
import ImageCropper from "../../../adminPanel/panelCommonComponents/ImageCropper";
import Modal from "../../../adminPanel/panelCommonComponents/Modal";
import { COLOR, PRODUCT } from "../../../constants";
import { confirm } from "../../../BTUI/BtAlert";


function UpdateProduct() {
  const [payload, setPayload] = useState({});
  const [imgPath, setImgPath] = useState(null)
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState(null);
  const [category, setCategory] = useState([]);
  const { id } = useParams(null);
  const [size, setSize] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const fetchDetail = () => {
    productService
      .details({ id })
      .then(async (resp) => {
        setPayload(resp?.data?.response?.details);
        setImgPath(resp?.data?.response)
        console.log(resp)
      })
      .catch((error) => {
        console.log("errors ", error);
      });
  };

  useEffect(() => {
    productService.categoryQuickList().then((resp) => {
      setCategory(resp?.data?.list);
    });
    productService.sizeQuickList().then((resp) => {
      setSize(resp?.data?.list);
    });
    fetchDetail();
  }, []);

  const handleChange = (name, value) => {
    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    productService
      .update({
        ...payload,
        id: id,
      })
      .then((resp) => {
        toast.success("Update Successfuly")
        navigate(-1);
      })
      .catch((err) => {
        setErrors(err?.errors);
        setFormError(err?.message);
      })
      .finally(()=>{setLoading(false)})
  };

  const handleImgChange=async(image, id, serial_number)=> {
    let file = new File ( [image], "image.png", { type: "image/png", lastModified: Date.now ()})
    
    productService
      .imageUpload({
        product_id: id,
        serial_number: serial_number,
        image: file,
      })

      .then((resp) => {
        toast.success('Uploaded Successfuly')
        fetchDetail();
      })
      .catch((err) => {

        console.log('Error, while uploading image',err);
      });
  }
  function handleImgDelete(serial_number) {
      productService
        .imageDelete({
          product_id: id,
          serial_number: serial_number,
        })

        .then((resp) => {
          fetchDetail();
          toast.success('Delete Successfuly')
        })
        .catch((err) => {
          console.log(err);
        });
  }
  return (
    <div className="panelProductUpdateSection w-100 flex-centered flex-column gap-2">
      <ToastContainer
        autoClose={1000}
      />
      <div className="panelProductUpdateDiv flex-column flex gap-2">
        <BackBtn
          url="/products"
          heading="Product Update"
          title="view"
          navigate={`/product-detail/${id}`}
        />
        <div className="flex gap-2">
          <div className="panelProductUpdateImgContainer flex-column gap-2">
            <div className="gap-2">
              {payload?.images?.map((img, i) => (
                <>

                <ImageSelector
                  key={i}
                  serialNumber={img?.serial_number}
                  productId={id}
                  url={imgPath?.image_path+img?.path}
                  setImage={(image) =>
                    handleImgChange(image, id, img?.serial_number)
                  }
                  refresh={fetchDetail}
                  handleImgDelete={()=>confirm(
                    'Are You Sure You want to Delete ?',
                    ()=>handleImgDelete(img?.serial_number),
                    ()=>{console.log('ON cancel function triggered')},
                    {
                        acceptTitle:'Delete',
                        acceptBackground: "#ef3f49"
                    }
                )}
                />
                </>
              ))}

              
              <ImageSelector
                  disableDelete
                  serialNumber={0}
                  productId={id}
                  // url={null}
                  setImage={(image) =>
                    handleImgChange(image, id, 0)
                  }
                  refresh={fetchDetail}
                  handleImgDelete={()=>null}
                />
            </div>
          </div>

          <div className="panelProductUpdateText flex-column gap-2">
            

            <SectionHeading title="Product Info" />

            <div className="flex gap-2">
              <Input
                name="name"
                label="Name"
                value={payload?.name}
                error={errors?.name}
                handleChange={handleChange}
              />
            </div>

            <div className="flex gap-2">
              <Input
                name="code"
                label="Code"
                value={payload?.code}
                error={errors?.code}
                handleChange={handleChange}
              />
              <Dropdown
                label="Category"
                name="category_id"
                selected={payload?.category_id}
                options={{list: category, name: 'name', value: 'id'}}
                handleChange={handleChange}
                error={errors?.category_id}
              />
            </div>
            <div className="flex gap-2">
              <Input
                name="mrp"
                label="MRP"
                value={payload?.mrp}
                handleChange={handleChange}
                error={errors?.mrp}
              />
              <Input
                name="sale_price"
                label="Sale Price"
                value={payload?.sale_price}
                handleChange={handleChange}
                error={errors?.sale_price}
              />

            </div>

            <div className="flex gap-2">
              <Checkbox
                name="is_new_arrival"
                label="Is New Arrival ?"
                value={payload?.is_new_arrival}
                handleChange={handleChange}
              />
            </div>

            <div className="">
              <TextArea
                name="description"
                label="Description"
                rows="10"
                value={payload?.description}
                handleChange={handleChange}
                error={errors?.description}
              />
            </div>

            <div className="">
              <TextArea
                name="shipping_returns"
                label="Shipping & Returns"
                rows="10"
                value={payload?.shipping_returns}
                error={errors?.shipping_returns}
                handleChange={handleChange}
              />
            </div>

            <SectionHeading title="Size Options" />
            <div className="flex gap-2">
              <CheckboxMulti
                options={size?.map((itm) => {
                  return { label: itm.name, value: itm.name };
                })}
                name="size"
                value={payload?.size}
                handleChange={handleChange}
              />
            </div>
            <SectionHeading title="SEO Info" />

            <div className="flex gap-2">
              <Input
                name="page_title"
                label="Page Title"
                value={payload?.page_title}
                handleChange={handleChange}
              />

              <Input
                name="page_keywords"
                label="Page Keywords"
                value={payload?.page_keywords}
                handleChange={handleChange}
              />
            </div>
            <div className="flex gap-2">
              <Input
                name="page_description"
                label="Page Description"
                value={payload?.page_description}
                handleChange={handleChange}
              />
            </div>

            <FormError text={formError} />
            <div className="flex-centered gap-2">
              <Button
                title="Update Product"
                variant="filled-btn"
                buttonLoading={loading}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const ImageSelector = (props) => {
  const [blob, setBlob] = useState(null);
  const [inputImg, setInputImg] = useState("");
  const [open, setOpen] = useState(true);
  const [imgPath,setImgPath]=useState(props?.url)
  const getBlob = (blob) => {
    setBlob(blob);
  };

  const onInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setInputImg(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleCropImage = (e) => {

    
    e.preventDefault();
    // setPreview(URL.createObjectURL(blob));
    props?.setImage(blob);
    setInputImg("");
  };
  const imageInput = useRef(null);
  const handleIconClick = () => {
    imageInput.current.click();
  };

  useEffect(()=>{
    setImgPath(props?.url)
  },[props?.url])
  
  return (
    <div
      className="imageFileSection"
      style={{
        backgroundPosition: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        paddingTop:PRODUCT.paddingTop
      }}
    >
      <div className="imageContainerAbs flex-centered">
        <img src={imgPath} onError={()=>setImgPath(PRODUCT.defaultImage)}/>
      </div>

      <input
        ref={imageInput}
        type="file"
        accept="image/*"
        onChange={onInputChange}
        style={{ display: "none" }}
      />
      {inputImg && (
        <CropModal
          getBlob={getBlob}
          inputImg={inputImg}
          handleCropImage={handleCropImage}
          setInputImg={setInputImg}
          open={open}
          setOpen={setOpen}
        />
      )}
      <div className="imageFileOverDiv flex-centered">
        <AiFillCamera
          style={{
            fill: "rgba(200,200,200,0.8)",
            fontSize: "5em",
            cursor: "pointer",
          }}
          onClick={handleIconClick}
        />
      </div>

      {!props.disableDelete ? (
        <div className="imageFileAbsDiv">
          <BsTrashFill
            style={{
              fill: "rgba(200,200,200,0.8)",
              fontSize: "1.5em",
              cursor: "pointer",
            }}
            onClick={props.handleImgDelete}
          />
        </div>
      ) : null}
    </div>
  );
};

const CropModal = ({ getBlob, inputImg, handleCropImage, setInputImg,open,setOpen }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const handleClose = () => {
    setOpen(false);
    setInputImg("");
  };

  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <div
            style={{
              width: "320px",
              height: "320px",
              position: "relative",
              backgroundColor: "grey",
              margin: "5px auto",
              // border: "2px solid orange",
            }}
          >
            <ImageCropper outputWidth={PRODUCT.width} outputHeight={PRODUCT.height} aspect={PRODUCT.ratio} getBlob={getBlob} inputImg={inputImg} />
          </div>

          <div className="flex-centered gap-2">
              <Button
                title="Crop"
                variant="filled-btn"
                onClick={handleCropImage}
              />
              <Button
                title="Cancel"
                variant="filled-btn"
                onClick={handleClose}
                style={{backgroundColor:COLOR.danger}}
              />
            </div>
        </div>
      </Modal>
    </div>
  );
};
export default UpdateProduct;
