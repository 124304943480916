import { useEffect, useState } from "react";


export function useTextLimit(text="", number) {
    // const number = 26;
    const [productHeadingText, setProductHeadingText] = useState('');

    useEffect(() => {
        if (text.length >= number) {
        setProductHeadingText(text.slice(0, number) + '...');
        } else {
        setProductHeadingText(text);
        }
    }, [text, number]);

    return productHeadingText;
}