import './style.css'
import logo from './../../Assets/images/logo.png'
import logoName from './../../Assets/images/logo-name.png'
const LoginLayout=({children})=>{

return <div className='login-layout-out'>
    <div className='login-layout-overlay'></div>
    <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <div style={{width:'400px'}}>
            <img src={logoName} style={{maxWidth:'100%',maxHeight:'100%'}} />
            </div>
        </div>
    <div className='login-layout-left'>
        <div>
            {/* <img src={logo} /> */}
        </div>
        {children}
    </div>
    <div className='login-layout-right'>
        {/* {children} */}
        </div>    
</div>
}

export default LoginLayout;
