import { deleteRequest, getRequest, postRequest, putRequest } from './../../axios';

/**
 * Api call
 */
class productService {

    addNew = async (payload) => {
        return await postRequest('manager/product', payload);
    }

    update = async (payload,id=null) => {
        return await putRequest('manager/product', payload);
    }

    details = async (urlParam = {}, payload) => {
        return await getRequest('manager/product', urlParam);
    }


    // list = async (urlParam = {}) => {
    //     return await getRequest('product/list', urlParam);
    // }

    list = async (urlParam = {}, payload) => {
        return await getRequest('manager/product/list', urlParam);
    }

    delete = async (id=null) => {
        return await deleteRequest(`manager/product?id=${id}`);
    }

    imageUpload = async (payload) => {
        
        const headers = {
            "Content-Type": "multipart/form-data",
        }

        return await postRequest('manager/product/image', payload,{
        headers:headers
        });
    }

    imageDelete = async (payload) => {
        
        return await deleteRequest('manager/product/image', payload);
    }

    
    categoryQuickList = async (urlParam = {}) => {
        return await getRequest('product-category/quick-list', urlParam);
    }

    sizeQuickList = async (urlParam = {}) => {
        return await getRequest('product-size/quick-list', urlParam);
    }

}

const instance = new productService();

export default instance;