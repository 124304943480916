import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './ProductList.css'
import { getProducts } from '../../../store/productSlice';
import Button from '../../../adminPanel/panelCommonComponents/Button/Button';
import productService from '../../../services/axios/product';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdvanceList, AdvanceListBody, AdvanceListPaggination, AdvanceListWrapper, ListRowActions } from '../../../BTUI/List';
import { toIndianCurrencyFormat } from '../../../adminPanel/panelCommonFunc/CurrencyFormat';
import { confirm } from '../../../BTUI/BtAlert';

import { Dropdown } from "../../components/Form";

function Product() {
    const [productList, setProductList] = useState([]);
    const [search, setSearch] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams()
    const [qParam, setQParam] = useState(null)
    const [listInfo, setListInfo] = useState({})
    const [category, setCategory] = useState([]);
    const dispatch = useDispatch();

    const navigate = useNavigate()

    useEffect(() => {
        if (!qParam) {
            return;
        }
        dispatch(getProducts({ ...qParam, search: search }))
            .then((resp) => {
                setProductList(resp?.response?.list)
                setListInfo(resp?.response?.listInfo)
                console.log(resp)
            })

    }, [qParam, search])
    useEffect(() => {
        setQParam({
            pageNo: parseInt(searchParams.get('pageNo')) || 1,
            pageSize: 10,
            sortByName: searchParams.get('sortByName') || "",
            sortByOrder: searchParams.get('sortByOrder') || "",
            category_id: searchParams.get('category_id') || null,
        })
        // console.log('Params getting change');
    }, [searchParams])

    const handleDelete = (id) => {
        productService.delete(id)
            .then((resp) => {
                setProductList(productList.filter((itm) => itm.id !== id))
                toast.success('Deleted Successfuly')
            })
            .catch((err) => {
                toast.error(err?.message)
            })

    }
    const handleSearch = (name, value) => {
        setSearch(value)
    }

    useEffect(() => {
        productService.categoryQuickList()
            .then((resp) => {
                setCategory(resp?.data?.list);
            });
    }, []);

    const handleChange = (name, value) => {
        if (name === 'category_id') {
            setSearchParams({ ...qParam, category_id: value });
        }
    };

    const Thead = [
        {
            title: 'Code',
            width: '10%',
            sortName: 'code',
        },
        {
            title: 'Name',
            width: '40%',
            sortName: 'name',
        },
        {
            title: 'Category',
            width: '15%',
            sortName: 'category',
        },
        {
            title: 'MRP',
            width: '10%',
            sortName: 'mrp',
            align: 'end'
        },
        {
            title: 'Sale Price',
            width: '10%',
            sortName: 'sale_price',
            align: 'end'
        },
        {
            title: 'Action',
            width: '15%',
            align: 'center'
        },
    ]

    return <>
        <AdvanceListWrapper
            header
            heading="Products"
            search
            handleSearchChange={handleSearch}
            addButton
            handleAdd={() => navigate("/add-product")}
        >
            <div className="filter-container">
                <div className="filter-item">
                    <Dropdown
                        label="Category"
                        name="category_id"
                        selected={qParam?.category_id}
                        options={{ list: category, name: 'name', value: 'id' }}
                        handleChange={handleChange}
                    />
                </div>
                <div className="filter-item"></div>
                <div className="filter-item"></div>
                <div className="filter-item"></div>
            </div>

            <AdvanceList
                thead={Thead}
                queryParams={qParam}
            >
                <AdvanceListBody>
                    {productList?.map((product, i) => {
                        return (
                            <tr key={i} >
                                <td>{product?.code}</td>
                                <td>{product?.name}</td>
                                <td>{product?.category}</td>
                                <td style={{ textAlign: 'right' }}>{toIndianCurrencyFormat(product?.mrp)}</td>
                                <td style={{ textAlign: 'right' }}>{toIndianCurrencyFormat(product?.sale_price)}</td>
                                <td>
                                    <ListRowActions actions={[
                                        { icon: 'edit', onClick: () => { navigate(`/product-update/${product?.id}`) } },
                                        { icon: 'view', onClick: () => { navigate(`/product-detail/${product?.id}`) } },
                                        {
                                            icon: 'delete', onClick: () => confirm(
                                                'Are You Sure You want to Delete ?',
                                                () => handleDelete(product?.id),
                                                () => { console.log('ON cancel function triggered') },
                                                {
                                                    acceptTitle: 'Delete',
                                                    acceptBackground: "#ef3f49"
                                                }
                                            )
                                        },
                                    ]} />
                                </td>
                            </tr>
                        )
                    })}
                </AdvanceListBody>
            </AdvanceList>
            <AdvanceListPaggination
                listInfo={listInfo}
                handlePrev={() => setSearchParams({ ...qParam, pageNo: qParam.pageNo - 1 })}
                handleNext={() => setSearchParams({ ...qParam, pageNo: qParam.pageNo + 1 })}
            />
        </AdvanceListWrapper>
    </>
}

export default Product