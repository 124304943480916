import { configureStore, combineReducers } from "@reduxjs/toolkit";

import productSlice from "./productSlice";
import customerSlice from "./customerSlice";
import orderSlice from "./orderSlice";
import authSlice, { setIsLoggedOn } from "./authSlice";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import filtersSlice from "./filtersSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authSlice,
  product: productSlice,
  customer: customerSlice,
  order: orderSlice,
  filter: filtersSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});


export const persistor = persistStore(store);

(async () => {
  try {
    const access_token=localStorage.getItem('token')
    if (access_token != '' && access_token != null && access_token != undefined && typeof access_token === 'string') {
      store.dispatch(setIsLoggedOn(true));
    }
  } catch (error) {
    store.dispatch(setIsLoggedOn(false));
    console.log("your token not exists in local storage")
  }
})();

export default store