import './style.css'


const BannerUpdate = ()=> {

    return<>
        
    </>
}

export default BannerUpdate;