import { deleteRequest, getRequest, postRequest, putRequest } from './../../axios';

/**
 * Api call
 */
class authService {

    getLogin = async (urlParam = {}) => {
        return await postRequest('manager/login', urlParam);
    }

    getLogout = async (urlParam = {}) => {
        return await postRequest('manager/logout', urlParam);
    }

    forgotPassword = async (urlParam = {}) => {
        return await postRequest('manager/forgot-password', urlParam);
    }

    resetPassword = async (urlParam = {}) => {
        return await postRequest('manager/reset-password', urlParam);
    }

    updatePassword = async (urlParam = {}) => {
        return await postRequest('manager/update-password', urlParam);
    }




    getCurrencyRateList = async (urlParam = {}) => {
        return await getRequest('manager/currency/list', urlParam);
    }

    getCurrencyRateDetails = async (id = {}) => {
        return await getRequest('manager/currency', id);
    }

    updateCurrencyRate = async (payload) => {
        return await putRequest('manager/currency', payload);
    }

    deleteCurrencyRate = async (id={}) => {
        return await deleteRequest('manager/currency', id);
    }
}

const instance = new authService();

export default instance;