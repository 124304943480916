import React from 'react'
import Button from '../Button/Button'
import { useNavigate } from 'react-router-dom'
import {BiArrowBack} from '@react-icons/all-files/bi/BiArrowBack.esm'

function BackBtn(props) {
   
    const navigate = useNavigate()
    
  return (<>
    <div className='w-100 flex'
            style={{
                background: 'white',
                borderRadius: '0.5em',
                padding: '1em 15px',
                marginBottom: '-2em',
                // boxShadow: '0 0 1em 0.1em rgba(0, 0, 0, 0.05)'
            }}
        >
            {/* <Button 
                title={<BiArrowBack />}
                variant="transparent-btn"
                onClick={()=> navigate(props.url)}
                style={{
                    fontSize: '1.5em'
                }}
            /> */}
            <h2 className='w-100' 
                style={{
                    // textAlign: 'center',
                    margin: ' 0',
                    fontSize: '1.2em',
                    fontWeight: '650',
                }}
            >{props.heading}</h2>
            { props.title && 
                <Button
                    title={props.title}
                    variant={props.variant}
                    onClick={() => navigate(props.navigate)}
                />
            }
            
        </div>
  </>
    
  )
}

export default BackBtn