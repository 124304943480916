import { AiFillCamera } from "@react-icons/all-files/ai/AiFillCamera.esm";
import { useEffect, useMemo, useRef, useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import Modal from "../../adminPanel/panelCommonComponents/Modal";
import ImageCropper from "../../adminPanel/panelCommonComponents/ImageCropper";
import Button from "../../adminPanel/panelCommonComponents/Button/Button";
import { COLOR } from "../../constants";
import './style.css'
const cropConfig = {
    width:500,
    height:500,
    outputWidth:500
}
const BtImageCropper=(props)=>{

    const [blob, setBlob] = useState(null);
    const [inputImg, setInputImg] = useState("");
    const [open, setOpen] = useState(true);
    const [imgPath,setImgPath]=useState(props?.url)
    const getBlob = (blob) => {
        // pass blob up from the ImageCropper component
        setBlob(blob);
    };

    const config = useMemo(()=>{
        const allConfigs = {...cropConfig, ...props?.config}
        return {
            ...allConfigs,
            get ratio() {
                let ratio = this.width/this.height;
                return parseFloat(ratio.toFixed(2))
            },
            get heightRatio() {
                let ratio = this.height/this.width;
                return parseFloat(ratio.toFixed(2))
            }
        }
    },[props?.config])
    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.addEventListener(
            "load",
            () => {
                setInputImg(reader.result);
            },
            false
        );

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleCropImage = (e) => {
        e.preventDefault();
        props?.setImagePath(URL.createObjectURL(blob));
        props?.handleImgChange(blob);
        setInputImg("");
    };
    const imageInput = useRef(null);
    const handleIconClick = () => {
        imageInput.current.click();
    };

    useEffect(()=>{
        setImgPath(props?.url)
    },[props?.url])
    
    return (
    <div
        className="croper-image-wrapper"
        style={{
            backgroundPosition: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            paddingTop: `${config.heightRatio*100}%`
        }}
    >
        <div className="cropper-image-preview">
            <img src={imgPath} style={{width: imgPath === '/static/media/product-400x500.56b15fde83ce34256326.png' ? '50%' : '100%'}} onError={()=>setImgPath(props.defaultImage)}/>
        </div>
        <input
            ref={imageInput}
            type="file"
            accept="image/*"
            onChange={onInputChange}
            style={{ display: "none" }}
        />
        {inputImg && (
        
        <CropModal
            getBlob={getBlob}
            inputImg={inputImg}
            handleCropImage={handleCropImage}
            setInputImg={setInputImg}
            open={open}
            setOpen={setOpen}
            config={config}
        />
        )}
        <div className="cropper-image-over-div flex-centered">
            <AiFillCamera
                style={{
                    fill: "rgba(200,200,200,0.8)",
                    fontSize: "5em",
                    cursor: "pointer",
                }}
                onClick={handleIconClick}
            />
        </div>

        {!props.disableDelete ? (
        <div className="cropper-image-abs-div">
            <BsTrashFill
                style={{
                    fill: "rgba(200,200,200,0.8)",
                    fontSize: "1.5em",
                    cursor: "pointer",
                }}
                onClick={props.handleImgDelete}
            />
        </div>
        ) : null}
    </div>
    );
}


export default BtImageCropper


const CropModal = ({ getBlob, inputImg, handleCropImage, setInputImg,open,setOpen,config }) => {
    const handleClose = () => {
        setOpen(false);
        setInputImg("");
    };
    return (
    <div>
        <Modal open={open} onClose={() => setOpen(false)}>
        <div>
            <div
                style={{
                    width: "500px",
                    height: `${500*config?.heightRatio}px`,
                    position: "relative",
                    backgroundColor: "lightgrey",
                    margin: "5px auto",
                }}
            >
            <ImageCropper outputWidth={config.outputWidth} outputHeight={config.outputWidth*config.heightRatio} aspect={config.ratio} getBlob={getBlob} inputImg={inputImg} />
            </div>

            <div className="flex-centered gap-2">
                <Button
                    title="Crop"
                    variant="filled-btn"
                    onClick={handleCropImage}
                />
                <Button
                    title="Cancel"
                    variant="filled-btn"
                    onClick={handleClose}
                    style={{backgroundColor:COLOR.danger}}
                />
            </div>
            
        </div>
        </Modal>
    </div>
    );
};