import defaultImage from './Assets/default/product-400x500.png'

export const STATUS_COLOR = {
    'NEW_ORDER': {
        color: 'rgba(10, 128, 240, 1)',
        bgColor: 'rgba(10, 128, 240, .1)'
    },
    'IN_PROCESS': {
        color: 'rgba(235, 119, 30, 1)',
        bgColor: 'rgba(235, 119, 30, .1)'
    },
    'IN_TRANSIT': {
        color: 'rgb(161, 9, 161)',
        bgColor: 'rgb(255, 232, 255)'
    },
    'DELIVERED': {
        color: 'rgb(0, 97, 0)',
        bgColor: '#e6ffe6'
    },

    'REJECTED': {
        color: 'rgb(180, 1, 1)',
        bgColor: 'rgb(253, 236, 232)'
    },
}

export const COLOR={
    primary: 'cyan',
    danger:'#a62117'
}

export const PRODUCT={
    ratio:1000/1500,
    paddingTop:(1500/1000)*100+`%`,
    width:1000,
    height:1500,
    defaultImage:defaultImage   
}

export const BANNER={
    DESKTOP:{
        ratio:1800/1000,
        paddingTop:(1800/1000)*100+`%`,
        width:1000,
        height:1800,
        defaultImage:defaultImage 
    },
    MOBILE:{
        ratio:500/500,
        paddingTop:(500/500)*100+`%`,
        width:500,
        height:500,
        defaultImage:defaultImage 
    }  
}