import './Form.css'
export const SectionHeading=(props)=>{
    return <h3 style={{...shStyle,...props.sty}}>{props.title}</h3>
}



SectionHeading.defaultProps={
    title:"title",
    sty:{}
}

const shStyle={
    color:'grey',
    fontSize:'16px'
}


export const FormError=(props)=>{
    return <h3 style={{...FEStyle,...props.sty}}>{props.text}</h3>
}

SectionHeading.defaultProps={
    text:"title",
    sty:{}
}

const FEStyle={
    color:'red',
    fontSize:'16px',
    textAlign:'center'
}

export const Dropdown = (props) => {
    return (
        <div className="dropdown-section">
            {props?.label && <label className="dropdown-label">{props?.label}</label>}
            <div className="dropdown-inner-div">
            <select
                className="dropdown"
                id={props.id}
                onChange={(e) =>
                props.handleChange(
                    props.name,
                    e?.target?.value,
                    props?.options?.list.find(
                    (itm) => itm[props?.options?.value] === e?.target?.value
                    )
                )
                }
                value={props?.selected}
            >
                <option value="0">--Select--</option>
    
                {props?.options?.list?.map((itm, index) => {
                return (
                    <option id={index} key={index} value={itm[props?.options?.value]}>
                    {itm[props?.options?.name]}
                    </option>
                );
                })}
            </select>
            </div>
            {props?.error && <p className="dropdown-error-text">{props.error}</p>}
        </div>
    );
  };

Dropdown.defaultProps={
    options:[],
    sty:{}
}

