import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    ordersFilter: [],
};

const filtersSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        setOrdersFilter:(state,action)=>{
            state.ordersFilter = action.payload
        },
        removeOrdersFilter:(state,action)=>{
            state.ordersFilter = state.ordersFilter.filter(itm=>itm.id!=action.payload)
        },
    },
    extraReducers: {},
});

export const { setOrdersFilter, removeOrdersFilter } = filtersSlice.actions;

export default filtersSlice.reducer;