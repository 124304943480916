import { IconBack } from '../Icons'
import './style.css'

export const FormMiniWrapper = (props)=>{

    return<div className="form-mini-wrapper">
        {props?.header && <div className="form-mini-wrapper-header">
            <div className="form-mini-wrapper-back-div">
                <IconBack onClick={props?.handleBack}/>
            </div>
            <div className="form-mini-wrapper-back-div">
                <h2>{props?.heading}</h2>
            </div>
            <div className="form-mini-wrapper-back-div">
                
            </div>
        </div>}
        
        <div className="form-mini-wrapper-body">
            {props?.children}
        </div>

        <div className="form-mini-wrapper-footer">
            <button className={`form-mini-btn ${props?.btnVarient}`} onClick={props?.handleSubmit}>
                {props?.buttonLoading ? "Loading" : props?.btnTitle}
            </button>
        </div>
    </div>
}

export const FormMiniGroup = ({children,heading}) => {

    return <>
        {heading && <h3 className="form-mini-group-heading">{heading}</h3>}
        <div className="form-mini-group-section">

            {children}
        </div>
    </>
}

export const FormMini = ({children}) => {

    return <div className="form-mini-section">
        {children}
    </div>
}