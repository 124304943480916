import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import './panelSlideMenu.css'
import { GiSunflower } from '@react-icons/all-files/gi/GiSunflower.esm'

import logo from './../../../Assets/images/logo.png'

const PanelOpen =(props)=>{

  return(
    <>
      <div className='panelSlideMenuLogo flex-centered'>
        <NavLink to="/"><GiSunflower /></NavLink>        
      </div>
      
      <div className='panelSlideMenu'>
        <ul className='flex-column flex'>
            <p style={{
              color: props.isToggle ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)',
              paddingBottom: '1em',
              fontSize: '0.8em',
              
              }}>Main</p>
          {props.menus.map((item, i)=>{
            return <li key={i}><NavLink 
                                className="flex gap-1 " 
                                onClick={item?.onClick} 
                                to={item.href} key={i} 
                                style={{alignItems: 'center',}}
                              >{item?.icon} {item.name}</NavLink></li>
          })}
        </ul>
      </div>
    </>
  )
}

const PanelClose = (props) => {


  return(
    <>
    <div style={props.style} className='panelSlideMenuLogo flex-centered'>
        <NavLink to="/"><img src={logo} style={{width:'50px',height:'50px'}} /></NavLink>        
      </div>
      
      <div className='panelSlideMenu'>
        <ul className='flex-column flex'>
            {/* <p style={{
              color: props.isToggle ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)',
              paddingBottom: '1em',
              fontSize: '0.7em',
              }}>Main</p> */}

          {props.menus.map((item, i)=>{
            return <li key={i}>
              {item?.isHover && 
              <div 
                className='panelClosehoverDiv'
                
                >{item?.name}</div>
              }

              <NavLink 
                className="panelCloseIcons flex gap-1" 
                to={item?.href} 
                onClick={item?.onClick}
                key={i} 
                // onMouseEnter={()=>item.onMouseEnter(i)}
                // onMouseLeave={()=>item.onMouseLeave(i)}
                style={{
                  
                }}
                >{item?.icon}</NavLink>
            </li>
          })}
        </ul>
      </div>
    </>
  )
}

function PanelSlideMenu(props) {

  const [isToggle, setIsToggle] = useState(false)

  return (
    <div className='panelSlideMenuSection flex-column' 
      style={{
        // width: '100%',
        // maxWidth: '18em',
      }}
    > 
    <div className='' 
      style={{
        display: props.isToggle ? 'block': 'none',
      }}
    >
      <PanelOpen 
          menus={props.menus}
        />
    </div>
    <div className=''
      style={{
        display: !props.isToggle ? 'block': 'none',
      }}
    >
      <PanelClose 
          menus={props.menus}
          isToggle={isToggle}
        />
    </div>
      
    </div>
  )
}

export default PanelSlideMenu