import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import './ForgotPass.css'

import {BiArrowBack} from '@react-icons/all-files/bi/BiArrowBack.esm'
import Input from '../../adminPanel/panelCommonComponents/Input/Input';
import Button from '../../adminPanel/panelCommonComponents/Button/Button';
import LoginLayout from '../LoginLayout';
import authService from '../../services/axios/auth';
import { ToastContainer, toast } from 'react-toastify';


function ForgotPass() {

    const [payLoad, setPayload] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = () => {
        setLoading(true)
        authService?.forgotPassword(payLoad)
            .then((res)=>{
                console.log(res)
                toast.success(res?.data?.message)
                navigate('/reset-password',{state: {data: payLoad?.email}})
            })
            .catch((err)=>{
                console.log(err)
                toast?.error(err.message)
            })
            .finally(()=>setLoading(false));
    }

    const handleChange = (name,value) => {
        setPayload({
            ...payLoad,
            [name]: value,
        })
    }

  return (
    <LoginLayout>
        <ToastContainer
            autoClose={3000}
        />
        <div className=' flex-column flex-centered gap-1' style={{width:'100%',maxWidth:'400px',background:'white',padding:'2em 1.2em',borderRadius:'15px',boxShadow:'0 0 10px -5px grey'}}>
            <div className='authBackground'>
                <NavLink to='/login'><BiArrowBack /></NavLink>
            </div>
            <div className='authHeading'>
                <h2>Forgot Password</h2>
            </div>
            <div className='authInputDiv flex-column w-100 gap-1'>
                <Input 
                    name="email"
                    label='Email'
                    handleChange={handleChange}
                />

                <NavLink className='forgotText' to='/login'>Login ?</NavLink>

                <div className='authBtndiv flex'>
                    <Button 
                        title="Next"
                        variant="btn-darkFill"
                        buttonLoading={loading}
                        onClick={handleSubmit}
                    />
                </div>
                <br/>
                <br/>
                {/* <NavLink className='authChangeLink' to='/sign-up'>Did'nt have an account? Register here</NavLink> */}

            </div>
            
        </div>
        </LoginLayout>
  )
}

export default ForgotPass