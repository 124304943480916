import { useMemo, useState } from "react";
import { FormMini, FormMiniGroup, FormMiniWrapper } from "../../../BTUI/Forms";
import "./style.css";
import { BANNER } from "../../../constants";
import bannerService from "../../../services/axios/banner";
import Input from "../../../adminPanel/panelCommonComponents/Input/Input";
import BtImageCropper from "../../../BTUI/BtImageCropper";
import { Dropdown } from "../../components/Form";
import { useNavigate} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';


const AddBanner = () => {
  const [imagePath, setImagePath] = useState(BANNER.DESKTOP.defaultImage);
  const [payload, setPayload] = useState({ type: "DESKTOP" });
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  
  const handleSubmit = () => {
    setLoading(true)
    bannerService
      .addNew(payload)
      .then((resp) => {
        toast.success(resp?.data?.message)
        navigate("/banner")
      })
      .catch((err) => {
        
        // console.log("Error, while uploading image", err);
        toast.error(err?.message)
      }).finally(()=>{setLoading(false)})
  };

  const handleImgChange = async (image) => {
    let file = new File([image], "image.png", {
      type: "image/png",
      lastModified: Date.now(),
    });
    setPayload({
      ...payload,
      image: file,
    });
  };

  const handleChange = (name, value) => {
    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const cropConfig = useMemo(() => {
    if (payload.type == "DESKTOP") {
      setImagePath(BANNER.DESKTOP.defaultImage);
      return {
        width: 1800,
        height: 1000,
        outputWidth: 1800,
      };
    } else {
      setImagePath(BANNER.MOBILE.defaultImage);
      return {
        width: 1000,
        height: 1000,
        outputWidth: 1000,
      };
    }
  }, [payload?.type]);
  return (
    <>
      <ToastContainer
            autoClose={1000}
        />
      <FormMiniWrapper
        header
        heading="Add Banner"
        btnTitle="Add"
        buttonLoading={loading}
        handleSubmit={handleSubmit}
      >
        <FormMiniGroup>
          <FormMini>
            <Input
              label="Serial Number"
              name="serial_number"
              value={payload?.serial_number}
              handleChange={handleChange}
            />
          </FormMini>
          <FormMini>
            <Dropdown
              label="Type"
              name="type"
              selected={payload?.type}
              options={{
                list: [
                  { name: "Desktop", value: "DESKTOP" },
                  { name: "Mobile", value: "MOBILE" },
                ],
                name: "name",
                value: "value",
              }}
              handleChange={handleChange}
            />
          </FormMini>
        </FormMiniGroup>
      </FormMiniWrapper>
      <br />
      <BtImageCropper
        config={cropConfig}
        url={imagePath}
        setImagePath={setImagePath}
        handleImgChange={handleImgChange}
      />
      <br />
    </>
  );
};

export default AddBanner;
