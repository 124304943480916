import React, { useEffect, useState } from "react";
import "./ProductDetail.css";
import { useParams } from "react-router-dom";
import productService from "../../../services/axios/product";
import BackBtn from "../../../adminPanel/panelCommonComponents/BackBtn/BackBtn";
import { PRODUCT } from "../../../constants";

function ProductDetail() {
  const [details, setDetails] = useState([]);
  const { id } = useParams();
  const [imagePath, setImagePath] = useState(null)

  useEffect(() => {
    productService
      .details({ id })
      .then(async (resp) => {
        setDetails(resp?.data?.response?.details);
        console.log(resp?.data);
        setImagePath(resp?.data?.response)
      })
      .catch((error) => {
        console.log("errors ", error);
      });
  }, []);

  const setImgSrc=(i)=>{
    console.log('showing error',i)
    setDetails({
      ...details,
      images:details?.images?.map((obj,ind)=>{
        if(i==ind){
          obj.path=PRODUCT.defaultImage
        }
        
        return obj
      })
    })
  }

  return (
    <div className="panelProductSection w-100 flex-centered flex-column gap-2">
      <div className="panelProductDiv flex-column gap-2 w-100">
        <BackBtn
          url="/products"
          heading="Product Details"
          title="edit"
          navigate={`/product-update/${id}`}
        />

        <div className="flex-row gap-2 w-100">
          <div className="panelProductDetailImgContainer flex-column gap-2 w-100">
            <div className=" gap-2" 
              style={{gridTemplateColumns: details?.images?.length>0 ?'1fr 1fr': '1fr',
                background: details?.images?.length>0 ? '' : 'rgba(245,245,245,1)'  
            }}
            >
              {details?.images?.length>0 ? details?.images?.map((img, i) => {
                return (
                  <div key={i}>
                    <div className="panelProductDetailImgDiv gap-1" style={{paddingTop:PRODUCT.paddingTop}}>
                      <div className="panelProductDetailImgAbsDiv flex">
                        <img src={(imagePath?.image_path && img?.path) ? imagePath?.image_path+img?.path : PRODUCT.defaultImage} onError={()=>setImgSrc(i)} width="" height="" />
                      </div>
                    </div>
                  </div>
                );
              }): <p style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>No image is selected yet</p>}
            </div>
          </div>

          <div className="productTextContentSection flex-column gap-1  w-100">
            <div className="productTextSection">

              <ProductDetailsCard title="Code" value={details?.code} />
              <ProductDetailsCard title="Name" value={details?.name} />
              <ProductDetailsCard
                title="Category"
                value={details?.category_name}
              />
              <ProductDetailsCard title="MRP" value={details?.mrp} />
              <ProductDetailsCard
                title="Sale Price"
                value={details?.sale_price}
              />
              {/* <ProductDetailsCard title="Tax" value={details?.tax} />
              <ProductDetailsCard
                title="Total Price"
                value={details?.total_price}
              /> */}
              <ProductDetailsCard
                title="Description"
                value={details?.description}
              />
              <ProductDetailsCard
                title="New Arrival"
                value={details?.is_new_arrival ? "Yes" : "No"}
              />
              <ProductDetailsCard
                title="Page Title"
                value={details?.page_title}
              />
              <ProductDetailsCard
                title="Page Keywords"
                value={details?.page_keywords}
              />
              <ProductDetailsCard
                title="Page Description"
                value={details?.page_description}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;


const ProductDetailsCard = (props) => {
  
  return (
    <>
      <div className="productDetailsCardSection w-100 flex-column">
        <hr />

        <div className="flex-between w-100">
          <h3 style={{ width: "30%", fontStyle: "italic" }}>{props.title}</h3>
          <p className="flex gap-1" style={{ width: "60%", flexGrow: 1 }}>
            {props.value}
          </p>
        </div>
      </div>
    </>
  );
};