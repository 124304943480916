import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import Order from '../Order/Order.js'
import { IconBan, IconDone, IconLoad, IconTruck } from '../../BTUI/Icons/index.js'
import orderService from '../../services/axios/order/index.js'


function Dashboard() {
    const [tracking, setTracking] = useState([])

    useEffect(()=>{
        orderService?.trackingWiseList()
            .then((res)=> {
                setTracking(res?.data?.response?.list)
            })
            .catch((err)=>{
            })
    },[])
  return (
    <div className="panelDashboardSection" style={{}}>
        
        <div className='panelDashboardContent '>
            <div className="flex flex-column gap-2">
                <DashboardCardWrapper>
                    {tracking?.map((card ,)=> {
                        return<DashboardCards 
                            key={card?.id}
                            title={card?.name}
                            count={card?.count>0 ? card?.count : 0}
                            icon={CardIcons?.[card?.code]}
                            leftColor={card?.color}
                            rightColor={card?.color_combo}
                        />
                        
                    })}
                    
                </DashboardCardWrapper>

                <Order />
                
            </div>
        </div>
    </div>
  )
}

export default Dashboard

const DashboardCardWrapper = ({children})=> {
    return<div className="dash-card-wrapper-section">
        {children}
    </div>
}

const DashboardCards = ({title,count,icon,leftColor,rightColor})=> {
    return<div className="dash-card-section"
        style={{
            background: `linear-gradient(to right, ${leftColor},${rightColor})`
        }}
    >
        <p>{title}</p>
        <h2>{count}</h2>
        <div className="dash-card-icon-abs">
            {icon}
        </div>
    </div>
}

const CardIcons={
    NEW_ORDER:<IconTruck />,
    IN_PROCESS:<IconLoad />,
    DELIVERED:<IconDone />,
    REJECTED:<IconBan />
}
