import './App.css';
import { BtConfirm } from './BTUI/BtAlert';
import AdminRouter from './Router';

function App() {
  return <>
    <BtConfirm/>
    <AdminRouter />
    </>;
}

export default App;
