import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../adminPanel/panelCommonComponents/Button/Button';
import { getCustomer } from '../../store/customerSlice';
import {BsEyeFill} from '@react-icons/all-files/bs/BsEyeFill.esm'
import './Customer.css'
import { AdvanceList, AdvanceListBody, AdvanceListPaggination, AdvanceListWrapper, ListRowActions } from '../../BTUI/List';

function Customer() {
    const [customerList, setCustomerList] = useState([]);
    const [search, setSearch] = useState(null);
    const [searchParams,setSearchParams] = useSearchParams()
    const [qParam,setQParam]=useState(null)
    const [listInfo,setListInfo]=useState({})
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if(!qParam){
            return;
        }
        dispatch(getCustomer({...qParam,search:search}))
            .then((resp) => {
                setCustomerList(resp?.response?.list)
                setListInfo(resp?.response?.listInfo)
            })
            .catch((error) => {
                // console.log('erro', error)
            })

    }, [qParam,search])

    useEffect(()=>{
        setQParam({
            pageNo:parseInt(searchParams.get('pageNo')) || 1,
            pageSize:10,
            sortByName:searchParams.get('sortByName') || "",
            sortByOrder:searchParams.get('sortByOrder') || "",
        })
    },[searchParams])

    const handleSearch = (name,value) => {
        setSearch(value);
    }

    const Thead = [
        {
            title: "Name",
            width: '20%',
            sortName:'name',
        },
        {
            title: "Email",
            width: '20%',
            // sortName:'email',
        },
        {
            title: "Mobile",
            width: '10%',
        },
        {
            title: "City",
            width: '10%',
            sortName:'city',
        },
        {
            title: "State",
            width: '10%',
            sortName:'state',
        },
        {
            title: "Country",
            width: '10%',
            sortName:'country',
        },
        {
            title: "Zipcode",
            width: '10%',
            sortName:'zipcode',
        },
        {
            title: "Action",
            width: '10%',
        },

    ]

    return (
        <AdvanceListWrapper
            header
            heading="Customers"
            search
            handleSearchChange={handleSearch}
        >
            <AdvanceList
                thead={Thead}
                sortByOrder={qParam?.sortByOrder}
                sortByName={qParam?.sortByName}
                queryParams={qParam}
            >
                <AdvanceListBody>
                    {customerList?.map((customer, i) => {
                        return (
                            <tr key={i}>
                                <td style={{ width: '30%' }}>{customer.name}</td>
                                <td>{customer.email}</td>
                                <td>{customer.mobile}</td>
                                <td>{customer.city}</td>
                                <td>{customer.state}</td>
                                <td>{customer.country}</td>
                                <td>{customer.zipcode}</td>
                                <td>
                                <ListRowActions actions={[
                                    {icon:'view',onClick:()=> { navigate(`/customer/${customer?.id}`)}},
                                ]}/>
                                </td>
                            </tr>
                        )
                    })}
                </AdvanceListBody>
            </AdvanceList>
            <AdvanceListPaggination 
                listInfo={listInfo}
                handlePrev={()=>setSearchParams({...qParam,pageNo:qParam.pageNo-1})}
                handleNext={()=>setSearchParams({...qParam,pageNo:qParam.pageNo+1})}
            />
        </AdvanceListWrapper>
    )
}

export default Customer