import React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import AdminPanel from '../AdminPanel'
import AddProduct from '../Pages/Product/AddProduct/AddProduct'
import Dashboard from '../Pages/Dashboard/Dashboard'
import ProductList from '../Pages/Product/ProductList/ProductList'
import Order from '../Pages/Order/Order'
import Customer from '../Pages/Customer/Customer'
import ProductDetail from '../Pages/Product/ProductDetail/ProductDetail'
import OrderDetails from '../Pages/Order/OrderDetail/OrderDetails'
import CustomerDetail from '../Pages/Customer/CustomerDetails/CustomerDetail'
import Login from '../Authentication/Login/Login'
import ForgotPass from '../Authentication/ForgotPass/ForgotPass'
import UpdateProduct from '../Pages/Product/UpdateProduct/UpdateProduct'
import {store} from './../store'
import ExchangeRate from '../Pages/ExchangeRate'
import Forms from '../Pages/Forms'
import Banner from '../Pages/Banner/BannerList'
import BannerDetail from '../Pages/Banner/BannerDetails'
import BannerUpdate from '../Pages/Banner/BannerUpdate'
import AddBanner from '../Pages/Banner/AddBanner'
import ResetPass from '../Authentication/ResetPass'
import UpdatePass from '../Authentication/UpdatePass'


const AllRoutes  = ()=>{
  const storeState = store.getState();
  const routes = [
    
    { path: '/', element: <AdminPanel />,

      children:[
        {index:true, element: <Dashboard />},
        {path:'/add-product', element: <AddProduct />},
        {path:'/forms', element: <Forms />},
        {path:'/product-detail/:id', element: <ProductDetail />},
        {path:'/product-update/:id', element: <UpdateProduct />},
        {path:'/products', element: <ProductList />},
        {path:'/orders', element: <Order />,},
        {path:'/orders/order-detail/:id', element: <OrderDetails />},

        {path:'/banner', element: <Banner />,},
        {path:'/banner/add', element: <AddBanner />},
        {path:'/banner/details/:id', element: <BannerDetail />},
        {path:'/banner/update/:id', element: <BannerUpdate />},

        {path:'/update-password', element: <UpdatePass />},

        {
          path:'/customer', element:<Customer/>,
          children:[
            {index:true,element:<h2>customer list</h2>},
            {path:'list',element:<h2>customer list</h2>},
            
          ]
        },
        {path:'/customer/:id',element:<CustomerDetail />},
        {path:'/customer/add-new', element:<h1>My user</h1>},
        {path:'/settings', element: <ExchangeRate />},

        
      ]
      
    },

    
    {path:'/login', element: <Login />},
    {path:'/forgot-password', element: <ForgotPass />},
    {path:'/reset-password', element: <ResetPass />},

    { path:'*', element:<h1>error 404</h1>}

  ]


  return useRoutes(routes)
}

function AdminRouter() {
  return (
    <BrowserRouter>
        <AllRoutes />
    </BrowserRouter>
  )
}

export default AdminRouter