import { AdvanceList, AdvanceListBody, AdvanceListPaggination, AdvanceListWrapper, List, ListBody, ListWrapper } from "../../BTUI/List"
import { useEffect, useState } from "react"
import authService from "../../services/axios/auth"
import Input from "../../adminPanel/panelCommonComponents/Input/Input"
import Button from "../../adminPanel/panelCommonComponents/Button/Button"
import { Switch } from "antd"
import { ToastContainer, toast } from "react-toastify"


const ExchangeRate = () => {
    const [exchangeRate, setExchangeRate] = useState([])

    useEffect(()=>{
        getList()
    },[])

    const getList = () => {
        authService.getCurrencyRateList()
            .then((res) => {
                setExchangeRate(res?.data?.response?.currency_exchange_rates || []); // Ensure exchangeRate is an array
            })
            .catch((err) => {
                // console.log("Error", err);
            });
    };

    const handleSubmit = (id) =>{
        authService.updateCurrencyRate({currency_exchange_rates: exchangeRate})
            .then((res) => {
                toast.success(res?.data?.message)
                getList()
            })
            .catch((err)=> {
                // console.log("update error", err); 
            })
    }

    const handleChange = (name, value) =>{
        setExchangeRate({
            ...exchangeRate,
            [name]: value
        })
    }

    const handleStatusChange = (checked, id) => {
        
        // console.log(`Switch ${checked ? 'ACTIVE' : 'INACTIVE'}, ID: ${id}`);
    };

    const handleChangeArray = (name, value, index) => {
        const updatedExchangeRate = [...exchangeRate]
        const newExchangeRate = {...updatedExchangeRate[index]}
        newExchangeRate[name] = value

        if(newExchangeRate['status']){
            newExchangeRate['status'] = "ACTIVE"
        }else{
            newExchangeRate['status'] = "INACTIVE"
        }
        
        updatedExchangeRate[index] = newExchangeRate

        
        setExchangeRate(updatedExchangeRate);
    }

    return<div className="">
        <ToastContainer 
            autoClose={5000}
        />
        <AdvanceListWrapper
            heading="Exchange Rate"
        >
            <AdvanceList thead={ExchangeRateHead}>
                <AdvanceListBody>
                    {exchangeRate?.map((itm,index) => {
                        return <tr key={itm.id}>
                            <td>{itm.id}</td>
                            <td>{itm.currency_name}</td>
                            <td>{itm.currency_code}</td>
                            <td>
                                <Input 
                                    name="rate"
                                    value={itm.rate}
                                    handleChange={(name,value)=>handleChangeArray(name,value,index)}
                                />
                            </td>
                            <td>
                                <Switch 
                                    size="small"
                                    checked={itm.status === 'ACTIVE'}
                                    onChange={(checked) => handleChangeArray('status',checked,index)}
                                />
                            </td>
                        </tr>
                    })}
                </AdvanceListBody>
            </AdvanceList>
            <hr/>
            {/* <AdvanceListPaggination /> */}
            <div style={{width: '100%', textAlign: 'center', padding: '2em 0'}}>
                <Button title="Save"  onClick={handleSubmit}/>
            </div>
        </AdvanceListWrapper>
    </div>
}

export default ExchangeRate

const ExchangeRateHead = [
    {
        title: 'Sr No',
        width: '10%'
    },
    {
        title: 'Currency Name',
        width: '20%'
    },
    {
        title: 'Currency Code',
        width: '20%'
    },
    {
        title: 'Rate',
        width: '20%'
    },
    {
        title: 'Status',
        width: '20%'
    },
    // {
    //     title: 'Action',
    //     width: '20%'
    // },
]
