import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Input from '../../adminPanel/panelCommonComponents/Input/Input';
import Button from '../../adminPanel/panelCommonComponents/Button/Button';
import authService from '../../services/axios/auth';
import { ToastContainer, toast } from 'react-toastify';
import PanelLayout from '../../adminPanel/panelComponents/PanelLayout/PanelLayout';
import { FormMini, FormMiniGroup, FormMiniWrapper } from '../../BTUI/Forms';


function UpdatePass() {
    // const location = useLocation();
    const [payLoad, setPayload] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = () => {
        setLoading(true)
        authService?.updatePassword(payLoad)
            .then((res)=>{
                console.log(res)
                toast.success(res?.data?.message)
                navigate('/login')
            })
            .catch((err)=>{
                console.log(err)
                toast?.error(err.message)
            })
            .finally(()=>setLoading(false));
    }

    const handleChange = (name,value) => {
        setPayload({
            ...payLoad,
            [name]: value,
        })
    }

  return (
        <div className="" style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

        }}>
            <ToastContainer
                autoClose={3000}
            />
            <div className='flex-column w-100 gap-1'
                style={{
                    width: '30%',
                    background: 'white',
                    borderRadius: '10px',
                    padding: '1.5em',
                    margin: '2em 0'
                }}
            >   
                <h2 style={{textAlign: 'center', fontSize: '1.5em', fontWeight: '600'}}>Update Password</h2>
                <br/>
                <Input 
                    name="old_password"
                    label='Old Password'
                    type="password"
                    handleChange={handleChange}
                />
                <Input 
                    name="new_password"
                    label='New Password'
                    type="password"
                    handleChange={handleChange}
                />
                <Input 
                    name="confirm_password"
                    label='Confirm Password'
                    type="password"
                    handleChange={handleChange}
                />
                <div className='authBtndiv flex'>
                    <Button 
                        title="Next"
                        variant="btn-darkFill"
                        buttonLoading={loading}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>
  )
}

export default UpdatePass