import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import './OrderDetails.css'
import Button from '../../../adminPanel/panelCommonComponents/Button/Button';
import orderService from '../../../services/axios/order';
import { toIndianCurrencyFormat } from '../../../adminPanel/panelCommonFunc/CurrencyFormat';
import { useTextLimit } from '../../../adminPanel/panelCommonFunc/TextLimit';
import BackBtn from '../../../adminPanel/panelCommonComponents/BackBtn/BackBtn';
import { Dropdown } from '../../components/Form';
import { DatePicker } from 'antd';
import Input from '../../../adminPanel/panelCommonComponents/Input/Input';
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';




const OrderDetails = (props) => {
    const { id } = useParams();
    const [orderListPayload, setOrderListPayload] = useState([]);
    const [errors, setErrors] = useState({})
    const [trackingStatus, setTrackingStatus] = useState([]);
    const [imagePath, setImagePath] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        orderService.details({ id })
            .then((resp) => {
                setOrderListPayload(resp?.data?.response);
                setImagePath(resp?.data?.response)
                console.log("orderPaylload", resp)
            })
            .catch((error) => {
                console.log("errors ", error);
            });
        orderService?.trackingStatusMaster()
            .then((res) => {
                setTrackingStatus(res?.data?.list)
            })
            .catch((err) => {
                console.log("error ", err)
            });
    }, [])

    useEffect(()=>{
        handleChange('tracking_expected_date',orderListPayload?.tracking_expected_date)
        console.log("tracking_expected_date", orderListPayload?.tracking_expected_date)
    },[orderListPayload?.tracking_location, orderListPayload?.tracking_status])

    const handleChange = (name, value) => {
        setOrderListPayload({
            ...orderListPayload,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: null
        })
    }

    const handleUpdate = () => {
        setLoading(true)
        orderService?.updateOrderTracking(orderListPayload)
            .then((res) => {
                toast?.success(res?.data?.message)
                console.log(res)
            })
            .catch((err) => {
                toast?.error(err?.message)
                console.log(err)
            })
            .finally(()=>{setLoading(false)})
    }

    return (
        <>
            <div className='myOrderDetailSection flex-centered flex-column gap-2 w-100'>
                <BackBtn
                    url="/orders"
                    heading="Order Details"
                />
                <ToastContainer
                    autoClose={1000}
                />
                <div className='flex gap-2'>
                    <div className='orderDetailImgContainer flex-column gap-2 w-100'>
                        <div className='flex-column gap-2 w-100'>
                            <div className='flex-column gap-1 w-100'>
                                <div className='orderDetailSummary flex-between gap-2 w-100'>
                                    <div className='orderDetailSummaryImg w-100'>
                                        <h2>Items Summary</h2>
                                    </div>
                                    <div className='w-50'>
                                        <h2>qty</h2>
                                    </div>
                                    {
                                        window.innerWidth >= 768 ? <>
                                            <div className='w-50'>
                                                <h2>Price</h2>
                                            </div>
                                            <div className=''
                                                style={{
                                                    // textAlign: 'right'
                                                }}
                                            >
                                                <h2>Total Price</h2>
                                            </div>
                                        </>
                                            : ''
                                    }
                                </div>
                                {orderListPayload?.product_detail?.map((product, i) => {
                                    return <OrderDetailCard
                                        img={imagePath?.thumb_path + product?.primary_image}
                                        productName={product?.name}
                                        quantity={product?.qty}
                                        price={product?.sale_price}
                                        totalPrice={product?.mrp}
                                    />
                                })}

                            </div>

                            <div className='orderedSummaryDetail flex-column gap-2'>
                                <div className='orderedSummaryDetailDiv flex-between flex-centered gap-2 w-100'>
                                    <div className='w-100'>
                                        <h2>Payment Summary</h2>
                                    </div>

                                </div>
                                <div className='flex-column gap-1'>
                                    <OrderSummaryCard
                                        name="Subtotal"
                                        value={orderListPayload?.sub_total && toIndianCurrencyFormat(orderListPayload?.sub_total)}
                                    />
                                    <OrderSummaryCard
                                        name="Tax"
                                        value={orderListPayload?.tax_total && `+ ${toIndianCurrencyFormat(orderListPayload?.tax_total)}`}
                                    />
                                    <OrderSummaryCard
                                        name="Delivery Fee"
                                        value={toIndianCurrencyFormat("0.00")}
                                    />
                                    <hr />
                                    <div className='orderDetailGrandTotalSection flex-column gap-1'>
                                        <div className='orderDetailGrandTotal flex-between gap-2 w-100'>
                                            <div className=''>
                                                <h2>Total</h2>
                                            </div>
                                            <div className=''>
                                                <h2>{orderListPayload?.grand_total && toIndianCurrencyFormat(orderListPayload?.grand_total)}</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='orderedSummaryDetail flex-column gap-2'>
                                <div className='orderedSummaryDetailDiv flex-between flex-centered gap-2 w-100'>
                                    <div className='w-100'>
                                        <h2>Order Summary</h2>
                                    </div>

                                </div>
                                <div className='flex-column gap-1'>
                                    <OrderSummaryCard 
                                        name="Order Time"
                                        value={orderListPayload?.order_at}
                                    />
                                    <OrderSummaryCard
                                        name='Payment Status'
                                        value={orderListPayload?.payment_status}
                                    />
                                    {orderListPayload?.payment_status === "SUCCESS" && <OrderSummaryCard
                                        name="Paid on"
                                        value={orderListPayload?.paid_on}
                                    />}
                                    {/* <OrderSummaryCard 
                                        name='Mode of Payment'
                                        value='UPI - lucky@okhedfc.ybl'
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='orderDetailTxtDiv flex-column gap-2'>
                        <div className='orderDetailTrackOrder flex-column gap-1'>
                            <h2>Track Order</h2>

                            <div className='flex-between flex-centered'>
                                <p>Status</p>

                                <div style={{ width: '30%' }}>
                                    <Dropdown
                                        name="tracking_status"
                                        selected={orderListPayload?.tracking_status}
                                        error={errors?.tracking_status}
                                        options={{ list: trackingStatus, name: 'name', value: 'id' }}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='datepicker flex-between flex-centered'>
                                <p>Delivery Expected Date</p>

                                <DatePicker
                                    disabledTime={dayjs()}
                                    value={(orderListPayload?.tracking_expected_date != '0000-00-00 00:00:00' && orderListPayload?.tracking_expected_date != null && orderListPayload?.tracking_expected_date != undefined) ? dayjs(orderListPayload?.tracking_expected_date)
                                        : !orderListPayload?.tracking_expected_date ? dayjs() : dayjs()}
                                    onChange={(date, dateString) => handleChange('tracking_expected_date', dateString)}
                                />
                            </div>
                            <div className='flex-between flex-centered'>
                                <p>Delivery Location</p>

                                <div style={{ width: '30%' }}>
                                    <Input
                                        name="tracking_location"
                                        error={errors?.tracking_location}
                                        value={orderListPayload?.tracking_location}
                                        handleChange={handleChange}
                                    />
                                </div>

                            </div>

                            <br />
                            <div className="flex-centered">
                                <Button
                                    title="Update Tracking Status"
                                    variant="submit"
                                    buttonLoading={loading}
                                    onClick={handleUpdate}
                                />
                            </div>
                        </div>

                        <div className='orderDetailSummarySection flex-column gap-1'>
                            <div className='orderDetailSummary flex-between gap-2 w-100'>
                                <div className='w-100'>
                                    <h2>Customer And Order Details</h2>
                                </div>

                            </div>
                            <hr />
                            <div className='flex-column'>
                                <CustomerDetailCard
                                    name='Customer Name'
                                    value={orderListPayload?.customer_detail?.name}
                                />
                                <CustomerDetailCard
                                    name='Phone Number'
                                    value={orderListPayload?.customer_detail?.mobile}
                                />
                                <CustomerDetailCard
                                    name='Email'
                                    value={orderListPayload?.customer_detail?.email}
                                />

                            </div>

                        </div>

                        <div className='deliveryAddressSection flex-column gap-2'>
                            <div className='deliveryAddressDiv flex-between flex-centered gap-2 w-100'>
                                <div className='w-100'>
                                    <h2>Delivery Address</h2>
                                </div>
                            </div>
                            <div className='flex-column gap-1'>
                                <DeliveryAddressCard
                                    name="Address line"
                                    value={orderListPayload?.customer_detail?.address1}
                                />
                                <DeliveryAddressCard
                                    name="Flat / Building"
                                    value={orderListPayload?.customer_detail?.address2}
                                />
                                <DeliveryAddressCard
                                    name="City, State"
                                    value={`${orderListPayload?.customer_detail?.city},  ${orderListPayload?.customer_detail?.state}`}
                                />
                                <DeliveryAddressCard
                                    name="Postcode"
                                    value={`${orderListPayload?.customer_detail?.zipcode}, ${orderListPayload?.customer_detail?.country}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderDetails;

const OrderDetailCard = (props) => {

    return (
        <>
            <hr />
            <br />
            <div className="orderDetailCardSection flex-between w-100 gap-2">
                <div className='orderDetailCardImg w-100 flex gap-1'>
                    <div className='orderDetailCardImgAbs flex-centered'>
                        <img src={props?.img} alt="Product" />
                    </div>
                    <h3>{useTextLimit(props?.productName, 40)}</h3>
                </div>
                <div className='quantity w-50'>
                    <p>{props?.quantity}</p>
                </div>
                {
                    window.innerWidth >= 768 ? <>
                        <div className='price w-50'>
                            <p>{props?.price && toIndianCurrencyFormat(props?.price)}</p>
                        </div>
                        <div className='totalPrice '
                            style={{
                                // textAlign: 'right'
                            }}
                        >
                            <p >{props?.totalPrice && toIndianCurrencyFormat(props?.totalPrice)}</p>
                        </div>
                    </>
                        : ''
                }
            </div>

        </>
    )
}


const CustomerDetailCard = (props) => {

    return (
        <>
            {/* <hr/> */}
            <div className="customerDetailCardSection flex-between w-100 gap-2">
                <div className='w-100 flex gap-1'>
                    <h3>{props?.name}</h3>
                </div>
                <div className='w-100'
                    style={{
                        // textAlign: 'right',
                    }}
                >
                    <p>{props?.value}</p>
                </div>
            </div>

        </>
    )
}

const OrderSummaryCard = (props) => {

    return (<>
        <div className='orderSummaryCardSection flex-between'>
            <div className=''>
                <h3>{props?.name}</h3>
            </div>
            <div className=''>
                <p>{props?.value}</p>
            </div>
        </div>
    </>)
}


const DeliveryAddressCard = (props) => {

    return (<>
        <div className='deliveryAddressCardSection flex gap-1'>
            <div className=''>
                <h3>{props?.name}</h3>
            </div>
            {
                window.innerWidth <= 380 ? ''
                    :
                    <div className=''>
                        <p>:</p>
                    </div>
            }
            <div className=''>
                <p>{props?.value}</p>
            </div>
        </div>
    </>)
}
