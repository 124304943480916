import { deleteRequest, getRequest, postRequest, putRequest } from '..';

/**
 * Api call
 */
class orderService {

    addNew = async (payload) => {
        return await postRequest('user/return-YR22/form', payload);
    }

    update = async (payload,id=null) => {
        return await putRequest('user/return-YR22/form?id='+id, payload);
    }

    details = async ({id=null}) => {
        return await getRequest('manager/order?order_id='+id);
    }

    list = async (urlParam = {}) => {
        return await getRequest('manager/order/list', urlParam);
    }

    delete = async (urlParam = {}) => {
        return await deleteRequest('user/return-YR22/form', urlParam);
    }

    trackingStatusMaster = async (urlParam = {}) => {
        return await getRequest('tracking-status/list', urlParam);
    }

    updateOrderTracking = async (urlParam = {}) => {
        return await putRequest('manager/update-tracking-status', urlParam);
    }

    trackingWiseList = async (urlParam = {}) => {
        return await getRequest('manager/order/tracking-wise-list', urlParam);
    }
}

const instance = new orderService();

export default instance;