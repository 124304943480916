import { useSearchParams } from "react-router-dom";
import { IconAddNew, IconDelete, IconDown, IconEdit, IconUp, IconView } from "../Icons";
import "./style.css";
import Button from "../../adminPanel/panelCommonComponents/Button/Button";



export const ListFilterButtons=(props)=>{

  const handleCheck = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      props.handleChange(props?.name, [...props?.selected, value]);
    } else {
      props.handleChange(props?.name, props?.selected?.filter((v) => v !== value));
    }
  };
  return <div className="list-filter-buttons" style={props?.style}>
  {
      props?.list?.map(sta=>{
          return <div key={sta?.value} className="lfb-button" style={{
            background:sta?.bgColor || '#f1f1f1',
            color:sta?.textColor || 'gray',
            borderColor:sta?.textColor || 'gray'
          }}>
            <input type='checkbox' checked={props?.selected.includes(sta?.value)} value={sta?.value} onChange={handleCheck}/> {sta?.title}
            </div>
      })
  }
</div>
}

export const ListFilter = ({children}) => {
  
  return<div className="list-filters">
    {children}
  </div>
}

export const FilterInput = (props) => {

  return<div className="list-filter-input">
    {props?.label && <label className="list-filter-input-label">{props?.label}: </label>}
    <input 
      name={props?.name}
      value={props?.value}
      placeholder={props?.hint}
      onChange={(e)=> props?.handleChange(props?.name, e?.target?.value)}
    />
  </div>
}

export const FilterDropdown = (props) => {

  return<div className="list-filter-dropdown">
      {props?.label && <label className="list-filter-dropdown-label">{props?.label}: </label>}
  {/* <div className="list-filter-dropdown-inner-div"> */}
      <select className="list-filter-dropdown-select" id={props.id}
          onChange={(e) => props.handleChange(props.name, e?.target?.value, props?.options?.list.find(itm=>itm[props?.options?.value]===e?.target?.value))}
          value={props?.selected}
      >
          <option value="0">--Select--</option>

          {props?.options?.list?.map((itm,index) => {
              return <option id={index} key={index} value={itm[props?.options?.value]}>{itm[props?.options?.name]}</option>
          })}
      </select>
  {/* </div> */}
</div>
}


export const ListRowActions = ({ children, actions = [] }) => {
  return (
    <div style={{display:'flex',columnGap:'12px',alignItems:'center',justifyContent:'center'}}>
      {actions.map((action,index) => {
        switch (action?.icon) {
          case "view":
            return <IconView key={index} size={action?.size || '15px'} onClick={action?.onClick} title={action?.title || 'View'}/>;
          case "edit":
            return <IconEdit key={index} size={action?.size || '15px'} onClick={action?.onClick} title={action?.title || 'Edit'}/>;
          case "delete":
            return <IconDelete key={index} size={action?.size || '15px'} onClick={action?.onClick} title={action?.title || 'Delete'}/>;
        }
      })}
    </div>
  );
};


export const AdvanceListWrapper = (props)=>{

  return<div className="advanceListOverview" style={{margin:'auto',...props?.style}}>
      <div className="advanceListOverviewHeader flex-between ">
          <h2 style={{ fontSize: '1.2em' }}>{props?.heading}</h2>
          <div className="advanceListOverviewSearchBox flex flex-centered gap-1">
              <div className=''>
                {props?.search && <input
                  className="advancelist-input"
                  name="search"
                  value={props?.searchValue}
                  placeholder={props?.searchHint || 'Search'}
                  onChange={(e) =>props?.handleSearchChange('search', e?.target?.value)}
                />}
                
              </div>
              {/* <ToastContainer
                autoClose={1000}
              /> */}
              <div className=''>
                {props?.addButton && <IconAddNew style={{fontWeight:'bold'}} onClick={props?.handleAdd}/>}
              </div>
          </div>
      </div>
      {props?.children}
  </div>
}

export const AdvanceList = (props) => {

  const [searchParams, setSearchParams] = useSearchParams()
  const {sortByName = "",sortByOrder = "DESC"} = props?.queryParams || {}
  const setSortBy =(name)=>{

    if(!props?.paramPrefix){
      setSearchParams({
        ...searchParams,
        sortByName:name,
        sortByOrder: (sortByName === name) ? (sortByOrder === 'DESC') ? 'ASC' : 'DESC' :  'DESC'})
    }else{
      setSearchParams({
        ...searchParams,
        [`${props?.paramPrefix}-sortByName`]:name,
        [`${props?.paramPrefix}-sortByOrder`]: (sortByName === name) ? (sortByOrder === 'DESC') ? 'ASC' : 'DESC' :  'DESC'})
    }

    
  }
  return<div className="advanceListOverviewContent w-100">
  <table className='w-100' cellSpacing="0">
      <AdvanceThead sortByOrder={sortByOrder} sortByName={sortByName} setSortBy={setSortBy} headers={props?.thead}/>
      {props?.children}
  </table>
</div>
}

AdvanceList.defaultProps={
  queryParams:{}
}


export const AdvanceListBody = ({children}) => {

  return<tbody>
  {children}
</tbody>
}

export const AdvanceListPaggination = ({listInfo, handlePrev, handleNext}) => {

  return<div className="productOverviewBottom w-100 flex-between">
  <div className="">
      <p>Showing <b>{listInfo?.totalRecords}</b> entries</p>
  </div>
  <div className="flex-centered gap-1">
    Page {listInfo?.pageNo}/{listInfo?.totalPages}
      <div className="">
          {
              (listInfo && listInfo.pageNo>1)? <Button title="prev" name="Prev" style={{padding: '1em 1em',}} onClick={handlePrev} />:''
          }
      </div>
      <div className='pagginationDiv flex'>
          {
              (listInfo && listInfo.pageNo<listInfo.totalPages)?<Button title="next" style={{padding: '1em 1em',}} name="Next" onClick={handleNext} />:''
          }
      </div>
  </div>
</div>
}

const AdvanceThead =({headers,setSortBy,sortByOrder,sortByName})=>{

  return<thead>
  <tr>
    {headers?.map((head, index) => {
      return (
        <th key={index} style={{ width: head?.width}} onClick={()=>head?.sortName ? setSortBy(head?.sortName) : null}>
          <p style={{justifyContent:head?.align || 'left'}}>{head?.title} <span>{head?.sortName ? (head.sortName === sortByName && sortByOrder =='ASC' ) ? <IconUp/> : <IconDown/> : null}</span></p>
        </th>
      );
    })}
  </tr>
</thead>
}

