import React from "react";
import "./Checkbox.css";

function CheckboxMutli(props) {
  const handleCheckChange = (value, isChecked) => {
    let curValue = props.value ? [...props.value] : [];
 
    if (isChecked) {
      curValue.push(value);
      props.handleChange(props.name, curValue);
    } else {
      props.handleChange(
        props.name,
        curValue.filter((itm) => itm != value)
      );
    }
  };
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '0.5em'}}>
      <div
        className="checkboxSection flex-centered gap-1"
        style={{ justifyContent: "flex-end",flexWrap:"wrap" }}
      >
        {props.options.map((itm) => {
          return (
            <div
              key={itm.value}
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "0 15px ",
              }}
            >
              <input
                className="checkbox"
                type="checkbox"
                checked={props?.value?.includes(itm?.value)}
                onChange={(e) =>
                  handleCheckChange(itm.value, e?.target?.checked)
                }
              />
              {itm?.label && (
                <label
                  className="checkboxLabel flex w-100"
                  style={{ marginLeft: "5px" }}
                >
                  {itm?.label}
                </label>
              )}
            </div>
          );
        })}
      </div>
      {props?.error && <span className="checkbox-error" >{props?.error}</span>}
    </div>
  );
}

const defaultProps = {
  options: [],
};

export default CheckboxMutli;
