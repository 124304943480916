import { FormMini, FormMiniGroup, FormMiniWrapper } from "../../BTUI/Forms"
import Input from "../../adminPanel/panelCommonComponents/Input/Input"

const Forms = () => {

    return<>
        <FormMiniWrapper
            header  
            heading="Add new Product"

            btnVarient="submit"
            btnTitle="Submit"
        >
            <FormMiniGroup
                heading="Product Info"
            >
                <FormMini>
                    <Input 
                        label="Name"
                        name=""
                        value=""
                        handleChange=""
                    />
                </FormMini>
                <FormMini>
                    <Input 
                        label="Name"
                        name=""
                        value=""
                        handleChange=""
                    />
                </FormMini>
                <FormMini>
                    <Input 
                        label="Name"
                        name=""
                        value=""
                        handleChange=""
                    />
                </FormMini>
                <FormMini>
                    <Input 
                        label="Name"
                        name=""
                        value=""
                        handleChange=""
                    />
                </FormMini>

                <FormMini>
                    <Input 
                        label="Name"
                        name=""
                        value=""
                        handleChange=""
                    />
                </FormMini>
                <FormMini>
                    <Input 
                        label="Name"
                        name=""
                        value=""
                        handleChange=""
                    />
                </FormMini>
                <FormMini>
                    <Input 
                        label="Name"
                        name=""
                        value=""
                        handleChange=""
                    />
                </FormMini>
                <FormMini>
                    <Input 
                        label="Name"
                        name=""
                        value=""
                        handleChange=""
                    />
                </FormMini>
                <FormMini>
                    <Input 
                        label="Name"
                        name=""
                        value=""
                        handleChange=""
                    />
                </FormMini>
                <FormMini>
                    <Input 
                        label="Name"
                        name=""
                        value=""
                        handleChange=""
                    />
                </FormMini>
            </FormMiniGroup>
        </FormMiniWrapper>
    
    </>
}

export default Forms