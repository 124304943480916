import React from 'react'
import './TextArea.css'

function TextArea(props) {
  return (
    <div className="textAreaSection flex-column ">
        {props?.label && <label className="textAreaLabel">{props.label}</label>}
        <textarea
            className="textArea"
            type="text"
            disabled={props.disabled}
            name={props.name}
            value={props.value}
            max={props.max}
            rows={props.rows}
            cols={props.cols}
            onChange={(e) => props.handleChange(props.name, e.target.value)}
        >{props.description}</textarea>
        {props?.error && <span className="inputError" >{props.error}</span>}
    </div>
  )
}

export default TextArea