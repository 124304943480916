import React from 'react'
import './Checkbox.css'

function Checkbox(props) {
  return (<>
  
    <div className="checkboxSection flex-centered gap-1">
        {props?.label && <label className="checkboxLabel flex w-100">{props.label}</label>}
        <input
            className="checkbox"
            type="checkbox"
            disabled={props?.disabled}
            name={props?.name}
            checked={props?.value}
            placeholder={props?.hint}
            value={props?.value}
            onChange={(e) =>props?.handleChange(props?.name, e?.target?.checked)}
        />
        {props?.warning && <span className="inputWarning" >{props.warning}</span>}
    </div>

    </>
  )
}

const defaultProps = {
    
}

export default Checkbox