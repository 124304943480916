import { createSlice } from "@reduxjs/toolkit";
import authService from "../services/axios/auth";

export const getLogin = (param,navigate) => async (dispatch) => {
  return authService
    .getLogin(param)
    .then(async (resp) => {
      dispatch(setIsLoggedOn(true))
      localStorage?.setItem("token", await resp?.data?.response?.token);
      dispatch(setToken(resp?.data?.response?.token))
      dispatch(setAuth(resp?.data));
      return resp;
    })
    .catch((error) => {
      dispatch(setAuth(null));
      localStorage.removeItem("token");
      throw error;
    });
};

export const getLogout = (navigate) => async (dispatch) => {
  return authService
    .getLogout()
    .then(async (res) => {
      dispatch(setAuth(null));
      localStorage.removeItem("token");
      dispatch(setIsLoggedOn(false))
      // navigate(navigatea);
      return res;
    })
    .catch((error) => {
      return error;
    });
};


const initialState = {
  auth: null,
  isLoggedIn:false,
  token:null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.success = true;
      state.auth = action.payload;
    },
    setIsLoggedOn: (state,action)=>{
      state.isLoggedIn=action.payload
    },
    setToken: (state,action)=>{
      state.token=action.payload
    }
  },
  extraReducers: {},
});

export const { setAuth, setIsLoggedOn,setToken } = authSlice.actions;

export default authSlice.reducer;
