import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import './Login.css'

import { BiArrowBack } from '@react-icons/all-files/bi/BiArrowBack.esm'
import { useDispatch } from 'react-redux';
import Input from '../../adminPanel/panelCommonComponents/Input/Input';
import Button from '../../adminPanel/panelCommonComponents/Button/Button';
import { getLogin } from '../../store/authSlice';
import LoginLayout from '../LoginLayout';

function Login() {

    const [payLoad, setPayload] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();

    const [authDetails, setAuthDetails] = useState({});
    const navigate = useNavigate();

    const handleChange = (name, value) => {
        setPayload({
            ...payLoad,
            [name]: value,
        })
    }

    async function handleAuth(){
        setLoading(true)
        await dispatch(getLogin(payLoad,navigate))
            .then((resp) => {
                navigate('/');
                // window.location.reload();
            })
            .catch((err) => {
                if (err?.errors) {
                    setErrors(err?.errors)
                }
            }).finally(()=>{setLoading(false)})
    }

    return <LoginLayout>
        <div className='flex-column flex-centered gap-1 auth-section' style={{ width: '100%', maxWidth: '450px', padding: '50px 25px', borderRadius: '15px', boxShadow: '0 0 10px -5px grey' }}>
            <div className='authBackground'>
                <NavLink to='/'><BiArrowBack /></NavLink>
            </div>
            <div className='authHeading'>
                <h2>Login</h2>
            </div>
            <div className='authInputDiv flex-column w-100 gap-1'>
                <Input
                    name="email"
                    label='Email'
                    handleChange={handleChange}
                    error={errors?.email}
                />

                <Input
                    name="password"
                    label='Password'
                    type="password"
                    handleChange={handleChange}
                    error={errors?.password}
                />

                <NavLink className='forgotText' to='/forgot-password'>Forgot password?</NavLink>

                <div className='authBtndiv flex'>
                    <Button
                        title="Login"
                        variant="btn-darkFill"
                        buttonLoading={loading}
                        onClick={handleAuth}
                    />
                </div>
                <br />
                <br />
            </div>

        </div>
    </LoginLayout>
}

export default Login