
import './style.css'
const Modal= (props)=>{
    if(props.open){
        return <>
            <div className="modal-out">
                
                <div className="modal-in">
                    <div style={{textAlign:'right'}}> <span onClick={props.onClose} style={{color:'red',cursor:'pointer',fontWeight:'bold',fontSize:'22px'}} title='Close'>X</span></div>
                    <div>{props.children}</div>
                </div>
            </div>
        </>
    }
}
Modal.defaultProps={
    open:false
}


export default Modal;