import { deleteRequest, getRequest, postRequest, putRequest } from '..';

/**
 * Api call
 */
class bannerService {

    addNew = async (payload) => {

        const headers = {
            "Content-Type": "multipart/form-data",
        }

        return await postRequest('manager/banner', payload,{
        headers:headers
        });
        // return await postRequest('manager/banner', payload);
    }

    update = async (payload,id=null) => {
        return await putRequest('user/return-YR22/form?id='+id, payload);
    }

    details = async ({id=null}) => {
        return await getRequest('manager/banner?id='+id);
    }

    list = async (urlParam = {}) => {
        return await getRequest('manager/banner/list', urlParam);
    }

    delete = async (urlParam = {}) => {
        return await deleteRequest('manager/banner', urlParam);
    }
}

const instance = new bannerService();

export default instance;