import React, { useEffect, useState } from 'react'
import './CustomerDetail.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toIndianCurrencyFormat } from '../../../adminPanel/panelCommonFunc/CurrencyFormat'
import orderService from '../../../services/axios/order'
import CustImg from '../../../Assets/images/person.jpg'
import { getDetails } from '../../../store/customerSlice'
import BackBtn from '../../../adminPanel/panelCommonComponents/BackBtn/BackBtn'


function CustomerDetail() {

    const dispatch = useDispatch();
    const {id} = useParams();

    const [userData, setUserData] = useState([])
    const [userOrders, setUserOrders] = useState([])
    const [imagePath, setImagePath] = useState(null)

    useEffect(() => {
        dispatch(getDetails({id}))
            .then((resp) => {
                setUserData(resp?.response)
                console.log("resp order details",resp)
            })
            .catch((err) => {
                // console.log("err ",err)
            })
        orderService?.list()
            .then((resp) => {
                console.log("Orders list ",resp?.data?.response)
                setImagePath(resp?.data?.response)
                setUserOrders(resp?.data?.response?.list)
            })
            .catch((err) => {
                // console.log("Error" , err)
            })
    },[])

    return (<>
            <div className='myProfileSection flex-centered flex-column gap-2 w-100'>
                <BackBtn 
                    url="/customer"
                    heading="Customer Detail"
                />
                <div className='flex-column gap-2 w-100' style={{marginTop: '2em'}}>
                    <div className='flex gap-2 w-100'>
                        <div className='myProfileOrdersSection w-70 flex-column flex-centered gap-1'>
                            <h2>Cutomer Orders</h2>
                            <hr/>
                            {(userData?.orders?.length<1 || userData?.orders == undefined || userData?.orders == null) ? <p>No order placed yet</p>:
                            <div className='myprofileOrderDiv w-100 gap-1'>
                                {userData?.orders?.map((orders, i) => {
                                    return(
                                        <OrderCard 
                                            img={imagePath?.image_path+orders?.product_detail?.[0]?.primary_image}
                                            price={toIndianCurrencyFormat(orders?.grand_total)}
                                            productName={`${orders?.product_detail?.[0]?.name} ${orders?.product_detail?.length > 1 ? "("+(orders?.product_detail?.length-1)+" more products)" : ''} `}
                                            quantity="1"
                                            id={orders?.order_id}
                                            alt=""
                                            status={orders?.payment_status}
                                        />
                                    )
                                })}
                                
                            </div>}
                            
                        </div>

                        <div className='myProfileInfo w-30 flex-column gap-1'>
                            <h2>Customer Profile</h2>
                            <hr/>
                            <div className='flex-column gap-2 flex-centered'>
                                <div className='myProfileInfoImg'>
                                    <img src={CustImg} width={150} />
                                </div>
                                <div className='myProfileInfoTxt flex-column gap-1'>
                                    <h3>{userData?.name}</h3>
                                    <p>{userData?.email}</p>
                                    <p> {userData?.mobile}</p>
                                </div>
                            </div>

                            <hr/>

                            <div className='myProfileInfoTxtAddress flex-column gap-1'>
                                <div className=''>
                                    <h3>Shipping Address</h3>
                                    <p>{(userData?.address1 || userData?.address2) && `${userData?.address1} ${userData?.address2},`}</p>
                                    <p>{(userData?.city || userData?.state) && `${userData?.city}, ${userData?.state}`}</p>
                                    <p>{(userData?.zipcode || userData?.country) && `${userData?.zipcode}, ${userData?.country}`}</p>
                                </div>
                                <div className=''>
                                    <h3>Billing Address</h3>
                                    <p>{userData?.billing_address && userData?.billing_address}</p>
                                    <p>{(userData?.billing_city || userData?.billing_state) && `${userData?.billing_city}, ${userData?.billing_state}`}</p>
                                    <p>{(userData?.billing_zipcode || userData?.billing_country)&&`${userData?.billing_zipcode}, ${userData?.billing_country}`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
    </>)
}

export default CustomerDetail



const OrderCard = (props) => {

    const navigate = useNavigate()

    return (
        <div className="orderCardSection flex-column" onClick={()=> navigate(`/orders/order-detail/${props.id}`)}>
            <div className='orderCardImg'>
                <div className='orderCardPriceAbs'>{props.price}</div>
                <div className='orderCardStatusAbs'
                    style={{
                        background: props?.status == 'PENDING' ? 'rgba(255, 251, 0, 0.5)' : 
                                    props?.status == 'SUCCESS' ? 'rgba(44, 150, 73, 0.5)' : 
                                    // props?.status == 'FAILED' ? '#ef3f49' :
                                    props?.status == 'ABORTED' ? 'rgba(230, 57, 57, .5)' : 'white',
                        fontSize: '0.9em',
                    }}
                >{props.status}</div>
                <div className='orderCardImgAbs flex-centered'>
                    <img className='flex-centered' src={props?.img} alt={`Order Image ${props?.alt}`}/>
                </div>
            </div>

            <div className='orderCardTxt'>
                <h2>{props.productName}</h2>
                <p>Quantity - {props.quantity}</p>
            </div>
        </div>
    )
}