import { createSlice } from "@reduxjs/toolkit";
import productService from "../services/axios/product";

export const getProducts = (param) => async (dispatch) => {
  // dispatch(loadingStatus(true));

  return productService
    .list(param)
    .then(async (res) => {
      dispatch(setList(res.data))
      return res.data;
    })
    .catch((error) => {
      console.log("error on product slice",error.request);
      return error;
    });
};



export const addProducts = (param) => async (dispatch) => {
    // dispatch(loadingStatus(true));
  
    return productService
      .list(param)
      .then(async (res) => {
        dispatch(setList(res.data))
        
        return res.data;
      })
      .catch((error) => {
        console.log("error on product slice");
        return error;
      });
  };



const initialState = {
  list: [],
  counter:0
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setCounter:(state,action)=>{
        state.counter=action.payload
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {},
});

export const { setCounter, setList } = productSlice.actions;

export default productSlice.reducer;
