
import { IoSendSharp } from "react-icons/io5";
import { IoIosEye, IoMdAdd } from "react-icons/io";
import { FaCaretRight, FaCaretUp } from "react-icons/fa";
import { FaCaretLeft } from "react-icons/fa";
import {AiOutlineLogout} from '@react-icons/all-files/ai/AiOutlineLogout.esm'
import { BiSolidMessage } from "react-icons/bi";
import { FiMail, FiPlus } from "react-icons/fi";
import { FiTrash2 } from "react-icons/fi";
import { IconContext } from "react-icons";
import { MdModeEdit } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaCaretDown } from "react-icons/fa";
import { FaHourglassStart } from "react-icons/fa";
import { FaTruck } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import { BsPatchCheckFill } from "react-icons/bs";
import { FaBan } from "react-icons/fa";

const Icon=(props)=>{
    return <IconContext.Provider value={{ color: "#a3a8ab", className: "global-class-name",...props }}>
    {
        props?.onClick 
        ? <div title={props?.title} style={{cursor:"pointer"}} onClick={props?.onClick}>
        {props?.children}
      </div>
        :<div title={props?.title}>
        {props?.children}
      </div>
    }
    
    
  </IconContext.Provider>
}


export const IconSendMessage=(props)=>{

    return <Icon {...props}>
        <IoSendSharp/>
    </Icon>
}

export const IconAddNew=(props)=>{

  return <Icon {...props}>
      <IoMdAdd size={30} color="black"/>
  </Icon>
}

export const IconView=(props)=>{

  return <Icon {...props}>
      <IoIosEye/>
  </Icon>
}

export const IconEdit=(props)=>{

  return <Icon {...props}>
      <MdModeEdit/>
  </Icon>
}

export const IconDelete=(props)=>{

  return <Icon {...props}>
      <FiTrash2/>
  </Icon>
}

export const IconNextList=(props)=>{

  return <Icon {...props}>
      <FaCaretRight/>
  </Icon>
}

export const IconPrevList=(props)=>{

  return <Icon {...props}>
      <FaCaretLeft/>
  </Icon>
}

export const IconLogout=(props)=>{

  return <Icon color="#fff" {...props}>
      <AiOutlineLogout />
  </Icon>
}

export const IconMessage=(props)=>{

  return <Icon {...props}>
      <BiSolidMessage />
  </Icon>
}

export const IconMail=(props)=>{

  return <Icon {...props}>
      <FiMail />
  </Icon>
}

export const IconBack=(props)=>{

  return <Icon {...props}>
      <IoMdArrowRoundBack />
  </Icon>
}

export const IconDown=(props)=>{

  return <Icon {...props}>
      <FaCaretDown />
  </Icon>
}

export const IconUp=(props)=>{

  return <Icon {...props}>
      <FaCaretUp />
  </Icon>
}

export const IconStar=(props)=>{

  return <Icon {...props}>
      <FaHourglassStart />
  </Icon>
}

export const IconTruck=(props)=>{

  return <Icon {...props}>
      <FaTruck />
  </Icon>
}

export const IconLoad=(props)=>{

  return <Icon {...props}>
      <TfiReload />
  </Icon>
}

export const IconDone=(props)=>{

  return <Icon {...props}>
      <BsPatchCheckFill />
  </Icon>
}

export const IconBan=(props)=>{

  return <Icon {...props}>
      <FaBan />
  </Icon>
}