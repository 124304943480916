import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getOrders } from '../../store/orderSlice';
import './Order.css'

import { AdvanceList, AdvanceListBody, AdvanceListPaggination, AdvanceListWrapper, ListFilterButtons, ListRowActions } from '../../BTUI/List';
import orderService from '../../services/axios/order';
import { STATUS_COLOR } from '../../constants';


function Order() {
    const OrderThead =[
        {
            title: 'Uid',
            width: '10%',
            sortName:'uid',
            
        },
        {
            title: 'Name',
            width: '15%',
            // sortName:'name',
            align:'start'
        },
        {
            title: 'Email',
            width: '15%',
            // sortName:'email',
            align:'start'
        },
        {
            title: 'Mobile',
            width: '15%',
            
        },
        {
            title: 'Tracking Status',
            width: '10%',
            sortName:'name'
        },
        {
            title: 'Exp. Del. Date',
            width: '10%',
            sortName:'tracking_expected_date'
        },
        {
            title: 'Address',
            width: '10%',
            // sortName:'address',
            align:'start'
        },
        {
            title: 'Action',
            width: '10%',
            align:'center'
        },

    ]

    const [orderList, setOrderList] = useState([]);
    const [orderSearch, setOrderSearch] = useState(null);
    const [trackingStatus, setTrackingStatus] = useState(null);
    const [filter, setFilter] = useState([]);
    const [searchParams,setSearchParams] = useSearchParams()
    const [qParam,setQParam]=useState(null)
    const [listInfo,setListInfo]=useState({})
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { ordersFilter=[] } = useSelector(store=>store.filter)

    useEffect(()=>{
        if(!qParam){
            return;
        }
        orderService?.trackingStatusMaster()
        .then((res)=>{
            setFilter(res?.data?.list?.map(itm=>{
                return {
                    value:itm?.id,
                    title:itm?.name,
                    bgColor: STATUS_COLOR?.[itm?.code]?.bgColor,
                    textColor: STATUS_COLOR?.[itm?.code]?.color,
                }
            }))
        })
        .catch((err)=>{
            // console.log("error ", err)
        });

    },[])

    useEffect(() => {
        dispatch(getOrders({...qParam,search: orderSearch, tracking_status: ordersFilter}))
            .then((resp) => {
                setOrderList(resp?.response?.list)
                setListInfo(resp?.response?.listInfo)
            })
            .catch((error) => {
            })
        orderService?.trackingStatusMaster()
            .then((res)=>{
                setTrackingStatus(res?.data?.list)
            })
            .catch((err)=>{
            });

    }, [orderSearch,qParam,filter])

    useEffect(()=>{
        setQParam({
            pageNo:parseInt(searchParams.get('pageNo')) || 1,
            pageSize:10,
            sortByName:searchParams.get('sortByName') || "",
            sortByOrder:searchParams.get('sortByOrder') || "",
        })
    },[searchParams])
    

    const handleChangeSearch = (name,value) => {
        setOrderSearch(value)
    }
    return (<>
        <AdvanceListWrapper
            heading="Orders"
            search
            handleSearchChange={handleChangeSearch}
            style={{width:'100%'}}
        >
            {/* <ListFilterButtons handleChange={(name,value)=>{dispatch(setOrdersFilter(value))}} selected={ordersFilter} list={filter} /> */}
            
            <AdvanceList
                thead={OrderThead}
                sortByOrder={qParam?.sortByOrder}
                sortByName={qParam?.sortByName}
                queryParams={qParam}
            >

                <AdvanceListBody>
                    {orderList?.map((order, i) => {
                        return (
                            <tr key={i}>
                                <td>{order?.uid}</td>
                                <td style={{textAlign:'left'}}>{order?.customer_detail?.name}</td>
                                <td style={{textAlign:'left'}}>{order?.customer_detail?.email}</td>
                                <td>{order?.customer_detail?.mobile}</td>
                                <td>{trackingStatus?.filter(status => status.id === order.tracking_status)
                                        .map(filteredStatus => filteredStatus.name)}
                                </td>
                                <td>{order?.tracking_expected_date !== '0000-00-00 00:00:00' && order?.tracking_expected_date}</td>
                                <td style={{textAlign:'left'}}>{order?.customer_detail?.zipcode}, {order?.customer_detail?.country}, {order?.customer_detail?.state}</td>
                                <td>
                                <ListRowActions actions={[
                                    {icon:'view',onClick:()=> {navigate(`/orders/order-detail/${order?.order_id}`)}},
                                ]}/>
                                </td>
                            </tr>
                            
                        )
                    })}
                </AdvanceListBody>
            </AdvanceList>
            <AdvanceListPaggination 
                listInfo={listInfo}
                handlePrev={()=>setSearchParams({...qParam,pageNo:qParam.pageNo-1})}
                handleNext={()=>setSearchParams({...qParam,pageNo:qParam.pageNo+1})}
            />
        </AdvanceListWrapper>
    </>
    )
}

export default Order