import { deleteRequest, getRequest, postRequest, putRequest } from './../../axios';

/**
 * Api call
 */
class customerService {

    details = async (id=null) => {
        return await getRequest(`manager/customer`,id);
    }

    list = async (urlParam = {}) => {
        return await getRequest('manager/customer/list', urlParam);
    }

}

const instance = new customerService();

export default instance;