import { useNavigate } from 'react-router-dom'
import { AdvanceList, AdvanceListBody, AdvanceListWrapper, ListRowActions } from '../../../BTUI/List'
import './style.css'
import { useEffect, useState } from 'react'
import bannerService from '../../../services/axios/banner'
import Button from '../../../adminPanel/panelCommonComponents/Button/Button'
import { confirm } from '../../../BTUI/BtAlert'
// import { BtToast, toastError, toastSuccess } from '../../../BTUI/BtToast'
import { ToastContainer, toast } from 'react-toastify';

const Banner = () => {

    const navigate = useNavigate()
    const [bannerList, setBannerList] = useState([])
    const [path, setPath] = useState('')
    useEffect(()=>{
        getList()
    },[])

    const getList=()=> {
        bannerService?.list()
            .then((res)=>{
                setBannerList(res?.data?.response?.list)
                setPath(res?.data?.response?.banner_thumb_path)
            })
            .catch((err)=>{
                // console.log(err)
            })
    }

    const handleDelete = (type, serial_number) => {
        bannerService?.delete({type, serial_number})
            .then((res) => {
                getList()
                toast.success(res?.data?.message)
            })
            .catch((err)=> {
                toast.error(err?.message)
            })
    }

    return<>
        <ToastContainer
            autoClose={1000}
        />
        <AdvanceListWrapper
            header
            heading="Banners"
            addButton="Add"
            handleAdd={()=>navigate('/banner/add')}
            style={{maxWidth:'800px'}}
        >
            <AdvanceList
                thead={Thead}
            >
                <AdvanceListBody>
                    {bannerList?.map((banner,index)=>{
                        return<tr key={index}>
                            <td><div style={{width:'150px'}}><ImageIcon image={path+banner?.image}/></div></td>
                            <td>{banner?.serial_number}</td>
                            <td>{banner?.type}</td>
                            <td>
                            <ListRowActions actions={[
                                    {icon:'view',onClick:()=>navigate('/banner/details/'+banner?.id)},
                                    {icon:'delete',onClick:()=>confirm(
                                        'Are You Sure You want to Delete ?',
                                        ()=>handleDelete(banner?.type,banner?.serial_number),
                                        ()=>{},
                                        {
                                            acceptTitle:'Delete',
                                            acceptBackground: "#ef3f49"
                                        }
                                    )},
                                ]}/>
                            </td>
                        </tr>
                    })}
                </AdvanceListBody>
            </AdvanceList>
        </AdvanceListWrapper>
    </>
}

export default Banner

const ImageIcon = ({image})=> {

    return<>
        <div className="image-icon-section">
            <div className="image-icon-abs-div">
                <img src={image}/>
            </div>
        </div>
    </>
}

const Thead = [
    {
        title: 'image',
        width: '40%',
    },
    {
        title: 'serial number',
        width: '20%',
    },
    {
        title: 'Type',
        width: '20%',
    },
    {
        title: 'Action',
        width: '20%',
        align:'center'
    },
]