import React, { useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {BiArrowBack} from '@react-icons/all-files/bi/BiArrowBack.esm'
import Input from '../../adminPanel/panelCommonComponents/Input/Input';
import Button from '../../adminPanel/panelCommonComponents/Button/Button';
import LoginLayout from '../LoginLayout';
import authService from '../../services/axios/auth';
import { ToastContainer, toast } from 'react-toastify';


function ResetPass() {
    const location = useLocation();
    const [payLoad, setPayload] = useState({
        email: location?.state?.data
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = () => {
        setLoading(true)
        authService?.resetPassword(payLoad)
            .then((res)=>{
                console.log(res)
                toast.success(res?.data?.message)
                navigate('/login')
            })
            .catch((err)=>{
                console.log(err)
                toast?.error(err.message)
            })
            .finally(()=>setLoading(false));
    }

    const handleChange = (name,value) => {
        setPayload({
            ...payLoad,
            [name]: value,
        })
    }

  return (
    <LoginLayout>
        <ToastContainer
            autoClose={3000}
        />
        <div className=' flex-column flex-centered gap-1' style={{width:'100%',maxWidth:'400px',background:'white',padding:'2em 1.2em',borderRadius:'15px',boxShadow:'0 0 10px -5px grey'}}>
            <div className='authBackground'>
                <NavLink to='/login'><BiArrowBack /></NavLink>
            </div>
            <div className='authHeading'>
                <h2>Set New Password</h2>
            </div>
            <div className='authInputDiv flex-column w-100 gap-1'>
                <Input 
                    name="email"
                    label='Email'
                    disabled
                    value={payLoad?.email}
                    handleChange={handleChange}
                />
                <Input 
                    name="code"
                    label='Otp'
                    handleChange={handleChange}
                />
                <Input 
                    name="password"
                    label='New Password'
                    type="password"
                    handleChange={handleChange}
                />
{/* 
                <NavLink className='forgotText' to='/login'>Login ?</NavLink> */}

                <div className='authBtndiv flex'>
                    <Button 
                        title="Next"
                        variant="btn-darkFill"
                        buttonLoading={loading}
                        onClick={handleSubmit}
                    />
                </div>
                {/* <br/> */}
                <br/>
                <NavLink style={{fontSize: '0.8em'}} className='authChangeLink' to='/sign-up'>Cancel</NavLink>

            </div>
            
        </div>
        </LoginLayout>
  )
}

export default ResetPass