import React, { useEffect, useState } from "react";
import Button from "../../../adminPanel/panelCommonComponents/Button/Button";
import Input from "../../../adminPanel/panelCommonComponents/Input/Input";
import "./AddProduct.css";

import { useNavigate } from "react-router-dom";
import TextArea from "../../../adminPanel/panelCommonComponents/TextArea/TextArea";
import productService from "../../../services/axios/product";
import { Dropdown, FormError, SectionHeading } from "../../components/Form";
import Checkbox from "../../../adminPanel/panelCommonComponents/Checkbox/Checkbox";
import BackBtn from "../../../adminPanel/panelCommonComponents/BackBtn/BackBtn";
import CheckboxMulti from "../../../adminPanel/panelCommonComponents/Checkbox/CheckboxMulti";
import { ToastContainer, toast } from "react-toastify";

function AddProduct() {
  const [payload, setPayload] = useState({});
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState(null);
  const [category, setCategory] = useState([]);
  const [size, setSize] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    productService.categoryQuickList()
      .then((resp) => {
        setCategory(resp?.data?.list);
      });
    productService.sizeQuickList().then((resp) => {
        setSize(resp?.data?.list)
    });
  }, []);

  const handleChange = (name, value) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    console.log(name, value);
  };

  const handleSubmit = (e) => {
    setFormError(null);
    setErrors({});
    setLoading(true);
    e.preventDefault();
    productService
      .addNew({
        ...payload,
      })
      .then((resp) => {
        toast.success("Product Added Successfully")
        navigate(-1);
      })
      .catch((err) => {
        toast?.error(err?.message)
        setErrors(err?.errors);
        setFormError(err?.message);
      }).finally(()=>{setLoading(false)})
  };

  return (
    <div className="addProductSection flex-column gap-2">
      <ToastContainer
        autoClose={1000}
      />
      <div className="addProductContent flex-column gap-2">
        <div className="" style={{marginLeft: '-15px'}}>
          <BackBtn url="/products" heading="Add New Product" />
        </div>
        <hr/>
        <SectionHeading title="Product Info" />
        <div className="flex gap-2">
          <Input
            name="name"
            label="Name"
            error={errors?.name}
            handleChange={handleChange}
          />

        </div>

        <div className="flex gap-2">
          
          <Input
            name="code"
            label="Code"
            error={errors?.code}
            handleChange={handleChange}
          />
          <Dropdown
            label="Category"
            name="category_id"
            selected={payload?.category_id}
            options={{list: category, name: 'name', value: 'id'}}
            handleChange={handleChange}
            error={errors?.category_id}
          />

        </div>
        <div className="flex gap-2">
          
        <Input
            name="mrp"
            label="MRP"
            error={errors?.mrp}
            handleChange={handleChange}
          />
          <Input
            name="sale_price"
            label="Sale Price"
            error={errors?.sale_price}
            handleChange={handleChange}
          />
        </div>

        <div className="flex gap-2">
          <Checkbox
            name="is_new_arrival"
            label="Is New Arrival"
            handleChange={handleChange}
          />
        </div>

        <div className="">
          <TextArea
            name="description"
            label="Product Description"
            rows="10"
            error={errors?.description}
            handleChange={handleChange}
          />
        </div>

        <div className="">
          <TextArea
            name="shipping_returns"
            label="Shipping & Returns"
            rows="10"
            error={errors?.shipping_returns}
            handleChange={handleChange}
          />
        </div>

        <SectionHeading title="Size Options" />
        <div className="flex gap-2">

            <CheckboxMulti
                options={size?.map(itm=>{
                    return {label:itm.name,value:itm.name}
                })}
                error={errors?.size}
                name='size'
                value={payload?.size}
                handleChange={handleChange}
            />
        </div>

        <SectionHeading title="SEO Info" />

        <div className="flex gap-2">
          <Input
            name="page_title"
            label="Page Title"
            handleChange={handleChange}
          />

          <Input
            name="page_keywords"
            label="Page Keywords"
            handleChange={handleChange}
          />
        </div>
        <div className="flex gap-2">
          <Input
            name="page_description"
            label="Page Description"
            handleChange={handleChange}
          />
        </div>

        <FormError text={formError} />
        <div className="flex-centered gap-2">
          <Button
            title="Add Product"
            variant="filled-btn"
            buttonLoading={loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
